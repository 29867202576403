<template>
  <iframe
    ref="iframeElement"
    :src="wekanUrl"
    allowfullscreen
    style="width: 100%; height: 92vh"
  >
  </iframe>
</template>

<script>
import User from "@/services/auth";
import { onMounted, ref } from "vue-demi";
export default {
  setup() {
    const iframeElement = ref(null);
    onMounted(() => {
      iframeElement.value.onload = () => {
        console.log(User.value, iframeElement.value);
        if (User.value?.Username && iframeElement.value) {
          iframeElement.value.contentWindow.postMessage({
            type: "AutoLogin",
            username: User.value?.Username.normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase(),
            email: User.value?.Email,
          });
        }
      };
    });

    return {
      iframeElement,
      wekanUrl: window._env_.WEKAN_URL
        ? window._env_.WEKAN_URL
        : "https://is.pronaht.sk/wekan",
    };
  },
};
</script>

<style></style>
