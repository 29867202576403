<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    max-width="500px"
    :loading="true"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"> </slot>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline"><slot name="headline"></slot></span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <slot name="form"></slot>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
