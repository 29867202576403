
















import { getEmptyCar } from "@/services/utils";
import { ref } from "vue-demi";
import CreateDialogBase from "./CreateDialogBase.vue";
import { useCreateOneCarMutation } from "@/generated/backend";
import CarForm from "./Forms/CarForm.vue";

export default {
  components: { CreateDialogBase, CarForm },

  setup(props, { emit }) {
    const form = ref(getEmptyCar());

    const {
      mutate: createCar,
      loading: creatingCar,
      onDone: creatingFinished,
    } = useCreateOneCarMutation({});

    const create = (cb) => {
      creatingFinished((data) => {
        cb();
        form.value = getEmptyCar();
        emit("created", data);
        console.log(form.value);
      });
      createCar({
        input: {
          car: form.value,
        },
      });
    };
    return {
      form,
      create,
      creatingCar,
    };
  },
};
