
















































































































































































































































































































































import UserMenu from "@/components/UserMenu.vue";
import { ref, watch } from "vue-demi";
import User from "../services/auth";

export default {
  components: { UserMenu },
  props: {
    sidebarOpen: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const sidebar = ref(true);
    const userRole = ref();

    const boardList = [
      "admin",
      "agenda",
      "agenda2",
      "tlmocnik",
      "tlmocnik2",
      "kontaktovatel",
      "kontaktovatel2",
    ];
    const companiesList = [
      "admin",
      "agenda",
      "agenda2",
      "tlmocnik",
      "tlmocnik2",
    ];
    const projectsList = [
      "admin",
      "agenda",
      "agenda2",
      "kontaktovatel2",
      "tlmocnik2",
    ];
    const usersList = ["admin"];
    const workersList = [
      "admin",
      "agenda",
      "agenda2",
      "kontaktovatel",
      "kontaktovatel2",
    ];
    const middlemansList = [
      "admin",
      "agenda",
      "agenda2",
      "kontaktovatel",
      "kontaktovatel2",
    ];
    const contractsList = ["admin", "agenda2", "tlmocnik2"];
    const carsList = ["admin", "agenda2", "tlmocnik2"];

    watch(User, (val) => {
      userRole.value = val.Role;
    });

    watch(
      () => props.sidebarOpen,
      (val) => {
        sidebar.value = val;
      }
    );

    return {
      User,
      userRole,
      boardList,
      companiesList,
      projectsList,
      middlemansList,
      usersList,
      workersList,
      contractsList,
      carsList,
      sidebar,
    };
  },
};
