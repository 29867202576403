























































import { Ref, ref } from "vue-demi";
import BaseTable from "./BaseTable.vue";
import { MiddlemenQuery, useMiddlemenQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import CreateMiddlemanDialog from "../Dialogs/CreateMiddlemanDialog.vue";
import EditMiddlemanDialog from "../Dialogs/EditMiddlemanDialog.vue";
import DeleteMiddlemanDialog from "../Dialogs/DeleteMiddlemanDialog.vue";
import router from "@/router";
import AttachmentViewer from "../AttachmentViewer.vue";

export default {
  components: {
    BaseTable,
    CreateMiddlemanDialog,
    EditMiddlemanDialog,
    DeleteMiddlemanDialog,
    AttachmentViewer,
  },
  setup() {
    const table = ref(null);

    const viewAttachments: Ref<string[]> = ref([]);
    const showAttachementClick = (item) => {
      const i = viewAttachments.value.indexOf(item.Id);
      if (i >= 0) {
        viewAttachments.value.splice(i);
      } else {
        viewAttachments.value.push(item.Id);
      }
    };

    return {
      table,
      viewAttachments,
      showAttachementClick,
      headers: [
        {
          text: "Meno",
          align: "start",
          value: "Name",
          searchable: true,
        },
        { text: "Telefón", value: "Phone", searchable: true },
        { text: "Popis", value: "Note", searchable: true, width: "20%" },
        {
          text: "Prílohy",
          value: "Attachments",
          searchable: true,
          searchFunc: (s) => ({
            attachments: {
              Name: {
                iLike: `%${s}%`,
              },
            },
          }),
          sortable: false,
          width: "40%",
        },
        { text: "", value: "actions", sortable: false },
      ],
      useMiddlemenQuery,
      itemsGetter: (result: Ref<MiddlemenQuery>) =>
        useResult(result, [], (d) => d.middlemen.nodes),
      totalCountGetter: (result: Ref<MiddlemenQuery>) =>
        useResult(result, 0, (d) => d.middlemen.totalCount),
      reloadTable: () => (table as any).value.reload(),
      onRowClick: (item) =>
        router.push({ name: "MiddlemanDetail", params: { id: item.Id } }),
      backendUrl: window._env_.BACKEND_URL,
    };
  },
};
