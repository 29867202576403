<template>
  <delete-dialog-base @remove="remove" :loading="deletingContract">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-delete
        </v-icon>
      </slot>
    </template>
    <template #headline>Odstrániť zmluvu s {{ lazyWorker.Name }}</template>
    <template #form>
      Naozaj chcete odstrániť zmluvu s {{ lazyWorker.Name }}?
    </template>
  </delete-dialog-base>
</template>

<script>
import DeleteDialogBase from "./DeleteDialogBase.vue";
import {
  useDeleteOneContractMutation,
  useDeleteOneContractPilverMutation,
} from "@/generated/backend";
import { ref, watch } from "vue-demi";

export default {
  components: { DeleteDialogBase },
  props: {
    contract: {
      type: Object,
    },
    companyType: {
      type: String,
    },
  },

  setup(props, { emit }) {
    const lazyWorker = ref(props.contract);
    watch(
      () => props.contract,
      (c) => (lazyWorker.value = c)
    );

    const {
      mutate: removeContract,
      loading: deletingContract,
      onDone: deletingFinished,
    } = props.companyType === "Pilver"
      ? useDeleteOneContractPilverMutation({})
      : useDeleteOneContractMutation({});

    const remove = (cb) => {
      deletingFinished((data) => {
        cb();
        emit("removed", data);
      });
      removeContract({
        input: { id: lazyWorker.value.Id },
      });
    };

    return {
      remove,
      lazyWorker,
      deletingContract,
    };
  },
};
</script>

<style></style>
