<template>
  <v-card class="tw-mx-auto tw-max-w-3xl" :loading="loading">
    <card-loader v-if="loading"></card-loader>
    <template v-else>
      <div class="tw-px-4 tw-pb-5 tw-pt-2 sm:tw-px-6 tw-flex">
        <router-link
          :to="{ name: 'MiddlemanDetail', params: { id: middleman.Id } }"
          class="
            tw-text-lg
            tw-leading-6
            tw-font-medium
            tw-text-gray-900
            tw-flex
            tw-items-center
          "
        >
          <v-icon color="primary" class="mr-2"
            >mdi-human-greeting-variant</v-icon
          >
          Sprostredkovateľ
          {{ middleman.Name }}
        </router-link>
        <v-spacer></v-spacer>
        <edit-middleman-dialog
          @updated="$emit('updated')"
          :middleman="middleman"
          v-slot:default="{ on, attrs }"
        >
          <v-btn fab depressed color="warning" small v-bind="attrs" v-on="on">
            <v-icon dense>mdi-pencil</v-icon>
          </v-btn>
        </edit-middleman-dialog>
        <delete-middleman-dialog
          @removed="$emit('removed')"
          :middleman="middleman"
          v-slot:default="{ on, attrs }"
        >
          <v-btn
            fab
            depressed
            color="error"
            small
            v-bind="attrs"
            v-on="on"
            class="ml-2"
          >
            <v-icon dense>mdi-delete</v-icon>
          </v-btn></delete-middleman-dialog
        >
      </div>
      <div class="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-p-0">
        <dl class="sm:tw-divide-y sm:tw-divide-gray-200">
          <card-row>
            <template #title>Meno</template>
            <template #value>{{ middleman.Name }}</template>
          </card-row>

          <card-row>
            <template #title>Email</template>
            <template #value>{{ middleman.Email }}</template>
          </card-row>

          <card-row>
            <template #title>Telefón</template>
            <template #value>{{ middleman.Phone }}</template>
          </card-row>

          <card-row>
            <template #title>Popis</template>
            <template #value>{{ middleman.Note }}</template>
          </card-row>

          <card-row>
            <template #title>Prílohy</template>
            <template #value>
              <attachment-viewer
                v-for="attachment in middleman.attachments"
                :key="attachment.Name"
                :url="`${backendUrl}/middlemanAttachment/${middleman.Id}/${attachment.Name}`"
                :name="attachment.Name"
              ></attachment-viewer>
            </template>
          </card-row>

          <card-row>
            <template #title>Aktualizované</template>
            <template #value>{{ middleman.UpdatedAt | luxon("f") }}</template>
          </card-row>
          <card-row>
            <template #title>Vytvorené</template>
            <template #value>{{ middleman.CreatedAt | luxon("f") }}</template>
          </card-row>
          <card-row>
            <template #title>Vytvoril</template>
            <template #value
              ><router-link
                v-if="middleman.createdBy"
                :to="{
                  name: 'UserDetail',
                  params: { id: middleman.createdBy.Id },
                }"
                >{{ middleman.createdBy.Username }}</router-link
              >
              <span v-else>Neznámy</span>
            </template>
          </card-row>
        </dl>
      </div>
    </template>
  </v-card>
</template>

<script>
import AttachmentViewer from "../AttachmentViewer.vue";
import DeleteMiddlemanDialog from "../Dialogs/DeleteMiddlemanDialog.vue";
import EditMiddlemanDialog from "../Dialogs/EditMiddlemanDialog.vue";
import CardLoader from "./CardLoader.vue";
import CardRow from "./CardRow.vue";
export default {
  components: {
    CardRow,
    CardLoader,
    EditMiddlemanDialog,
    DeleteMiddlemanDialog,
    AttachmentViewer,
  },
  props: {
    loading: { type: Boolean },
    middleman: { type: Object },
  },
  setup() {
    return {
      backendUrl: window._env_.BACKEND_URL,
    };
  },
};
</script>

<style></style>
