




























































import { nextTick, Ref, ref, watch } from "vue-demi";
import BaseTable from "./BaseTable.vue";
import {
  ProjectsQuery,
  SortDirection,
  useProjectsQuery,
  useProjectChangesQuery,
  ProjectChangeSortFields,
} from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import CreateProjectDialog from "../Dialogs/CreateProjectDialog.vue";
import EditProjectDialog from "../Dialogs/EditProjectDialog.vue";
import DeleteProjectDialog from "../Dialogs/DeleteProjectDialog.vue";
import router from "@/router";
import Calendar from "../calendar.vue";
import { DateTime } from "luxon";
import ProjectStateAutocomplete from "../Autocomplete/ProjectStateAutocomplete.vue";

export default {
  components: {
    BaseTable,
    CreateProjectDialog,
    EditProjectDialog,
    DeleteProjectDialog,
    Calendar,
    ProjectStateAutocomplete,
  },
  setup() {
    const table = ref(null);
    const fromDate = ref(null);
    const status = ref(null);

    watch([fromDate, status], () => {
      nextTick(() => {
        (table as any).value.searchChanged();
      });
    });

    return {
      table,
      fromDate,
      status,
      headers: [
        {
          text: "Aktualizované",
          value: "UpdatedAt",
          sortable: true,
          searchable: false,
        },
        {
          text: "Názov",
          align: "start",
          value: "Name",
          searchable: true,
        },
        {
          text: "Stav",
          value: "State",
          sortable: false,
          searchable: true,
          searchFunc: (s) => ({
            projectChange: {
              State: {
                iLike: `%${s}%`,
              },
            },
          }),
        },

        {
          text: "Typ",
          value: "Type",
          searchable: true,
        },

        { text: "", value: "actions", sortable: false },
      ],
      query: useProjectsQuery,
      itemsGetter: (result: Ref<ProjectsQuery>) =>
        useResult(result, [], (d) => d.projects.nodes),
      totalCountGetter: (result: Ref<ProjectsQuery>) =>
        useResult(result, 0, (d) => d.projects.totalCount),
      reloadTable: () => (table as any).value.reload(),
      onRowClick: (item) =>
        router.push({ name: "ProjectDetail", params: { id: item.Id } }),
    };
  },
};
