<template>
  <combobox-base
    @input="$emit('input', $event)"
    :query="query"
    :itemsGetter="itemsGetter"
    :value="value"
    label="Záujem"
  ></combobox-base>
</template>

<script>
import { useInterestsQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import ComboboxBase from "./ComboboxBase.vue";
import { useInterestsPilverQuery } from "../../generated/backend";

export default {
  components: { ComboboxBase },
  props: {
    value: { type: String },
    type: { type: String },
  },
  setup(props) {
    return {
      query: (v) =>
        props.type === "Pilver"
          ? useInterestsPilverQuery()
          : useInterestsQuery(),
      itemsGetter: (result) =>
        props.type === "Pilver"
          ? useResult(result, [], (d) => d.interestsPilver)
          : useResult(result, [], (d) => d.interests),
    };
  },
};
</script>
<style></style>
