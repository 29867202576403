import { Ref } from "@vue/composition-api";
import { ref, reactive, computed, watch, set, del } from "vue-demi";
import Notification from "./Notification";

export default abstract class NotificationsService {
  public static notifications: Notification[] = reactive([]);

  static get Notifications() {
    return this.notifications;
  }

  static Add(notification: Notification) {
    return set(this.notifications, notification.Id, notification);
  }

  static Remove(id: number) {
    return del(this.notifications, id);
  }
}

watch(NotificationsService.notifications, console.log);
