<template>
  <edit-dialog-base @update="update" :loading="creatingUser">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-pencil
        </v-icon>
      </slot>
    </template>
    <template #headline>Upraviť užívateľa {{ lazyUser.Name }}</template>
    <template #form>
      <user-form v-model="lazyUser"></user-form>
    </template>
  </edit-dialog-base>
</template>

<script>
import UserForm from "./Forms/UserForm.vue";
import EditDialogBase from "./EditDialogBase.vue";
import { useUpdateOneUserMutation } from "@/generated/backend";
import { ref, watch, watchEffect } from "vue-demi";
export default {
  components: { EditDialogBase, UserForm },
  props: {
    user: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const lazyUser = ref(props.user);
    watch(
      () => props.user,
      (c) => (lazyUser.value = c)
    );

    const {
      mutate: updateUser,
      loading: creatingUser,
      onDone: creatingFinished,
    } = useUpdateOneUserMutation({});

    const update = (cb) => {
      creatingFinished((data) => {
        cb();
        emit("updated", data);
      });
      const {
        __typename,
        Id,
        CreatedAt,
        DeletedAt,
        UpdatedAt,
        createdCompanies,
        createdProjects,
        contacts,
        projectChanges,
        createdWorkers,
        createdContracts,
        createdMiddlemans,
        ...updateItem
      } = lazyUser.value;
      updateUser({
        input: { update: updateItem, id: lazyUser.value.Id },
      });
    };

    return {
      update,
      lazyUser,
      creatingUser,
    };
  },
};
</script>

<style></style>
