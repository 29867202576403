<template>
  <create-dialog-base @create="create" :loading="creatingCompany">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-btn color="primary" depressed dark class="" v-bind="attrs" v-on="on">
          Nová firma {{ type }}
        </v-btn>
      </slot>
    </template>
    <template #headline> Vytvoriť novú firmu pre {{ type }}</template>
    <template #form>
      <company-form :type="type" v-model="form"></company-form>
    </template>
  </create-dialog-base>
</template>

<script>
import { getEmptyCompany } from "@/services/utils";
import { ref } from "vue-demi";
import CompanyForm from "./Forms/CompanyForm.vue";
import CreateDialogBase from "./CreateDialogBase.vue";
import {
  useCreateOneCompanyMutation,
  useCreateOneCompanyPilverMutation,
} from "@/generated/backend";
import router from "@/router";

export default {
  components: { CreateDialogBase, CompanyForm },
  props: {
    type: { type: String },
  },
  setup(props, { emit }) {
    const form = ref(getEmptyCompany());
    const {
      mutate: createCompany,
      loading: creatingCompany,
      onDone: creatingFinished,
    } = props.type === "Pilver"
      ? useCreateOneCompanyPilverMutation({})
      : useCreateOneCompanyMutation({});

    const create = (cb) => {
      creatingFinished((data) => {
        cb();
        form.value = getEmptyCompany();
        const res =
          props.type === "Pilver"
            ? data.data.createOneCompanyPilver
            : data.data.createOneCompany;
        emit("created", res);

        router.push({
          name: "CompanyDetail",
          params: { type: props.type, id: res.Id, on: true },
        });
      });

      if (props.type === "Pilver") {
        createCompany({ input: { companyPilver: form.value } });
      } else {
        createCompany({ input: { company: form.value } });
      }
    };

    return {
      form,
      create,
      creatingCompany,
    };
  },
};
</script>

<style></style>
