<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-text-field
        dense
        :value="value.Username"
        @input="update({ Username: $event })"
        label="Používateľské meno"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12">
      <v-text-field
        dense
        :value="value.Password"
        @input="update({ Password: $event })"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        name="password"
        label="Heslo (nezmenené)"
        required
        @click:append="showPassword = !showPassword"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12">
      <v-text-field
        dense
        type="email"
        :value="value.Email"
        @input="update({ Email: $event })"
        label="Email"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12">
      <v-text-field
        dense
        :value="value.Status"
        @input="update({ Status: $event })"
        label="Status"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12">
      <user-roles-autocomplete
        :value="value.Role"
        @input="update({ Role: $event })"
      ></user-roles-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from "vue-demi";
import UserRolesAutocomplete from "../../Autocomplete/UserRolesAutocomplete.vue";

export default {
  components: { UserRolesAutocomplete },
  props: {
    value: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const showPassword = ref(false);
    return {
      update: (updated) => emit("input", { ...props.value, ...updated }),
      showPassword,
    };
  },
};
</script>

<style></style>
