<template>
  <v-autocomplete
    :value="value"
    @update:search-input="$emit('input', $event)"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    :label="label"
    hide-details
    dense
    clearable
  ></v-autocomplete>
</template>

<script>
import { ref, watch, watchEffect } from "vue-demi";
export default {
  props: {
    label: { type: String },
    query: { type: Function },
    itemsGetter: { type: Function },
    value: {
      type: String,
    },
    default: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const search = ref(null);
    const { result, loading, refetch } = props.query({
      filter: props.defaultFilter,
    });
    const items = props.itemsGetter(result);

    watchEffect(() => {
      if (props.default && props.default.Id !== props.value) {
        items.value.push(props.default);
        emit("input", props.default.Id);
      }
    });

    watch(search, (val) => {
      // Lazily load input items
      refetch({ filter: { Name: { iLike: `%${val}%` } } });
    });

    return {
      search,
      loading,
      items,
    };
  },
};
</script>
<style></style>
