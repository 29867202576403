









































import { ref } from "@vue/composition-api";
import { useLoginMutation } from "../generated/backend";
import { defineComponent } from "@vue/composition-api";
import { useResult } from "@vue/apollo-composable";
import { Login } from "@/services/auth";
import router from "../router";

export default defineComponent({
  setup() {
    const password = ref("");
    const username = ref("");
    const showPassword = ref(false);
    const error = ref("");

    const {
      mutate: login,
      loading,
      onDone,
      onError,
    } = useLoginMutation(() => ({
      variables: {
        input: {
          username: username.value,
          password: password.value,
        },
      },
    }));

    onError((e) => (error.value = e.message));
    onDone((d) => {
      Login(d.data?.login?.accessToken as string);
      router.push("/");
    });

    return {
      password,
      username,
      showPassword,
      loading,
      login,
      error,
    };
  },
});
