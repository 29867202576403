

























import NotificationsContainer from "@/components/NotificationsContainer.vue";
import Vue from "vue";
import NavigationDrawer from "@/components/NavigationDrawer.vue";
import UserMenu from "../components/UserMenu.vue";

export default Vue.extend({
  components: { NotificationsContainer, NavigationDrawer, UserMenu },
  name: "AppLayout",

  data() {
    return {
      sidebarOpen: true,
    };
  },
});
