<template>
  <edit-dialog-base @update="update" :loading="creatingCar">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-pencil
        </v-icon>
      </slot>
    </template>
    <template #headline>Upraviť auto {{ lazyWorker.Name }}</template>
    <template #form>
      <car-form v-model="lazyWorker"></car-form>
    </template>
  </edit-dialog-base>
</template>

<script>
import EditDialogBase from "./EditDialogBase.vue";
import { useUpdateOneCarMutation } from "@/generated/backend";
import { ref, watch, watchEffect } from "vue-demi";
import CarForm from "./Forms/CarForm.vue";
import { useUpdateOneCarPilverMutation } from "../../generated/backend";
export default {
  components: { EditDialogBase, CarForm },
  props: {
    car: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const lazyWorker = ref(props.car);
    watch(
      () => props.car,
      (c) => (lazyWorker.value = c)
    );

    const {
      mutate: updateCar,
      loading: creatingCar,
      onDone: creatingFinished,
    } = useUpdateOneCarMutation({});

    const update = (cb) => {
      creatingFinished((data) => {
        cb();
        emit("updated", data);
      });
      const {
        __typename,
        Id,
        CreatedAt,
        DeletedAt,
        UpdatedAt,
        createdBy,
        ...updateItem
      } = lazyWorker.value;
      updateItem.attachments = updateItem.attachments.map((a) => {
        const { UpdatedAt, CreatedAt, DeletedAt, __typename, ...attachment } =
          a;
        return attachment;
      });
      updateCar({
        input: { update: updateItem, id: lazyWorker.value.Id },
      });
    };

    return {
      update,
      lazyWorker,
      creatingCar,
    };
  },
};
</script>

<style></style>
