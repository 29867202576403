<template>
  <edit-dialog-base @update="update" :loading="creatingMiddleman">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-pencil
        </v-icon>
      </slot>
    </template>
    <template #headline
      >Upraviť sprostredkovateľa {{ lazyMiddleman.Name }}</template
    >
    <template #form>
      <middleman-form v-model="lazyMiddleman"></middleman-form>
    </template>
  </edit-dialog-base>
</template>

<script>
import MiddlemanForm from "./Forms/MiddlemanForm.vue";
import EditDialogBase from "./EditDialogBase.vue";
import { useUpdateOneMiddlemanMutation } from "@/generated/backend";
import { ref, watch, watchEffect } from "vue-demi";
export default {
  components: { EditDialogBase, MiddlemanForm },
  props: {
    middleman: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const lazyMiddleman = ref(props.middleman);
    watch(
      () => props.middleman,
      (c) => (lazyMiddleman.value = c)
    );

    const {
      mutate: updateMiddleman,
      loading: creatingMiddleman,
      onDone: creatingFinished,
    } = useUpdateOneMiddlemanMutation({});

    const update = (cb) => {
      creatingFinished((data) => {
        cb();
        emit("updated", data);
      });
      const {
        __typename,
        Id,
        CreatedAt,
        DeletedAt,
        UpdatedAt,
        createdBy,
        ...updateItem
      } = lazyMiddleman.value;
      updateItem.attachments = updateItem.attachments.map((a) => {
        const { UpdatedAt, CreatedAt, DeletedAt, __typename, ...attachment } =
          a;
        return attachment;
      });
      updateMiddleman({
        input: { update: updateItem, id: lazyMiddleman.value.Id },
      });
    };

    return {
      update,
      lazyMiddleman,
      creatingMiddleman,
    };
  },
};
</script>

<style></style>
