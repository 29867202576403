<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </slot>
    </template>

    <v-list>
      <v-list-item to="#">
        <v-list-item-title @click="Logout">Odhlásiť</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { Logout } from "../services/auth";
export default {
  setup() {
    return {
      Logout,
    };
  },
};
</script>

<style></style>
