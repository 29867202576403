<template>
  <v-row>
    <v-col cols="12" sm="12">
      <project-combobox
        :value="value.Name"
        @input="update({ Name: $event })"
      ></project-combobox>
    </v-col>
    <v-col cols="12" sm="12">
      <v-text-field
        dense
        :value="value.Address"
        @input="update({ Address: $event })"
        label="Adresa"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12">
      <project-language-autocomplete
        :value="value.Language"
        @input="update({ Language: $event })"
      ></project-language-autocomplete>
    </v-col>
    <v-col cols="12" sm="12">
      <project-type-combobox
        :value="value.Type"
        @input="update({ Type: $event })"
      ></project-type-combobox>
    </v-col>
    <v-col cols="12" sm="12">
      <v-text-field
        dense
        :value="value.Manager"
        @input="update({ Manager: $event })"
        label="Vedúci projektu"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12">
      <v-text-field
        dense
        :value="value.ManagerContact"
        @input="update({ ManagerContact: $event })"
        label="Kontakt na vedúceho"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import ProjectCombobox from "@/components/Autocomplete/ProjectCombobox.vue";
import ProjectLanguageAutocomplete from "@/components/Autocomplete/ProjectLanguageAutocomplete.vue";
import ProjectTypeCombobox from "@/components/Autocomplete/ProjectTypeCombobox.vue";

export default {
  components: {
    ProjectCombobox,
    ProjectLanguageAutocomplete,
    ProjectTypeCombobox,
  },
  props: {
    value: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    return {
      update: (updated) => {
        console.log(updated);
        emit("input", { ...props.value, ...updated });
      },
    };
  },
};
</script>

<style></style>
