<template>
  <middlemans-table class="tw-max-w-7xl tw-mx-auto tw-m-8"></middlemans-table>
</template>

<script>
import MiddlemansTable from "@/components/Tables/MiddlemansTable.vue";

export default {
  components: { MiddlemansTable },
};
</script>

<style></style>
