























































import { Ref, ref } from "vue-demi";
import BaseTable from "./BaseTable.vue";
import { WorkersQuery, useWorkersQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import CreateWorkerDialog from "../Dialogs/CreateWorkerDialog.vue";
import EditWorkerDialog from "../Dialogs/EditWorkerDialog.vue";
import DeleteWorkerDialog from "../Dialogs/DeleteWorkerDialog.vue";
import router from "@/router";
import AttachmentViewer from "../AttachmentViewer.vue";

export default {
  components: {
    BaseTable,
    CreateWorkerDialog,
    EditWorkerDialog,
    DeleteWorkerDialog,
    AttachmentViewer,
  },
  setup() {
    const table = ref(null);

    const viewAttachments: Ref<string[]> = ref([]);
    const showAttachementClick = (item) => {
      const i = viewAttachments.value.indexOf(item.Id);
      if (i >= 0) {
        viewAttachments.value.splice(i);
      } else {
        viewAttachments.value.push(item.Id);
      }
    };

    return {
      table,
      viewAttachments,
      showAttachementClick,
      headers: [
        {
          text: "Meno",
          align: "start",
          value: "Name",
          searchable: true,
        },
        { text: "Telefón", value: "Phone", searchable: true },
        { text: "Popis", value: "Note", searchable: true, width: "15%" },
        { text: "Typ", value: "Type", searchable: true },
        { text: "Profesia", value: "Profession", searchable: true },
        {
          text: "Prílohy",
          value: "Attachments",
          searchable: true,
          searchFunc: (s) => ({
            attachments: {
              Name: {
                iLike: `%${s}%`,
              },
            },
          }),
          sortable: false,
          width: "40%",
        },
        { text: "", value: "actions", sortable: false },
      ],
      useWorkersQuery,
      itemsGetter: (result: Ref<WorkersQuery>) =>
        useResult(result, [], (d) => d.workers.nodes),
      totalCountGetter: (result: Ref<WorkersQuery>) =>
        useResult(result, 0, (d) => d.workers.totalCount),
      reloadTable: () => (table as any).value.reload(),
      onRowClick: (item) =>
        router.push({ name: "WorkerDetail", params: { id: item.Id } }),
      backendUrl: window._env_.BACKEND_URL,
    };
  },
};
