<template>
  <v-card class="tw-mx-auto tw-max-w-3xl" :loading="loading">
    <card-loader v-if="loading"></card-loader>
    <template v-else>
      <div class="tw-px-4 tw-pb-5 tw-pt-2 sm:tw-px-6 tw-flex">
        <router-link
          :to="{ name: 'CompanyDetail', params: { id: company.Id } }"
          class="
            tw-text-lg
            tw-leading-6
            tw-font-medium
            tw-text-gray-900
            tw-flex
            tw-items-center
          "
        >
          <v-icon color="primary" class="mr-2">mdi-domain</v-icon>
          Firma {{ company.Name }}
        </router-link>
        <v-spacer></v-spacer>
        <edit-company-dialog
          @updated="$emit('updated')"
          :company="company"
          :type="type"
          v-slot:default="{ on, attrs }"
        >
          <v-btn fab depressed color="warning" small v-bind="attrs" v-on="on">
            <v-icon dense>mdi-pencil</v-icon>
          </v-btn>
        </edit-company-dialog>
        <delete-company-dialog
          @removed="$emit('removed')"
          :company="company"
          :type="type"
          v-slot:default="{ on, attrs }"
        >
          <v-btn
            fab
            depressed
            color="error"
            small
            v-bind="attrs"
            v-on="on"
            class="ml-2"
          >
            <v-icon dense>mdi-delete</v-icon>
          </v-btn></delete-company-dialog
        >
      </div>
      <div class="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-p-0">
        <dl class="sm:tw-divide-y sm:tw-divide-gray-200">
          <card-row>
            <template #title>Názov</template>
            <template #value>{{ company.Name }}</template>
          </card-row>
          <card-row>
            <template #title>Adresa</template>
            <template #value>{{ company.Address }}</template>
          </card-row>
          <card-row>
            <template #title>Zameranie</template>
            <template #value>{{ company.Focus }}</template>
          </card-row>
          <card-row>
            <template #title>Web</template>
            <template #value
              ><a :href="company.Webpage">{{ company.Webpage }}</a></template
            >
          </card-row>
          <card-row>
            <template #title>Jazyk</template>
            <template #value>{{ company.Language }}</template>
          </card-row>
          <card-row>
            <template #title>Aktualizované</template>
            <template #value>{{ company.UpdatedAt | luxon }}</template>
          </card-row>
          <card-row>
            <template #title>Vytvorené</template>
            <template #value>{{ company.CreatedAt | luxon }}</template>
          </card-row>
          <card-row>
            <template #title>Vytvoril</template>
            <template #value
              ><router-link
                v-if="company.createdBy"
                :to="{
                  name: 'UserDetail',
                  params: { id: company.createdBy.Id },
                }"
                >{{ company.createdBy.Username }}</router-link
              >
              <span v-else>Vymazaný</span>
            </template>
          </card-row>
        </dl>
      </div>
    </template>
  </v-card>
</template>

<script>
import { useCompanyQuery } from "@/generated/backend";
import DeleteCompanyDialog from "../Dialogs/DeleteCompanyDialog.vue";
import EditCompanyDialog from "../Dialogs/EditCompanyDialog.vue";
import CardLoader from "./CardLoader.vue";
import CardRow from "./CardRow.vue";
import { useResult } from "@vue/apollo-composable";

export default {
  components: { CardRow, CardLoader, EditCompanyDialog, DeleteCompanyDialog },
  props: {
    loading: { type: Boolean },
    company: { type: Object },
    type: { type: String },
  },
};
</script>

<style></style>
