<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-text-field
        dense
        :value="value.Name"
        @input="update({ Name: $event })"
        label="Meno"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12">
      <v-text-field
        dense
        type="tel"
        :value="value.Phone"
        @input="update({ Phone: $event })"
        label="Telefón"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12">
      <v-text-field
        type="email"
        dense
        :value="value.Email"
        @input="update({ Email: $event })"
        label="Email"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12">
      <v-textarea
        dense
        :value="value.Note"
        @input="update({ Note: $event })"
        label="Popis"
      ></v-textarea>
    </v-col>
    <v-col cols="12" sm="12">
      <attachments-upload
        :value="value.attachments"
        @input="update({ attachments: $event })"
      ></attachments-upload>
    </v-col>
  </v-row>
</template>

<script>
import AttachmentsUpload from "@/components/AttachmentsUpload.vue";

export default {
  components: { AttachmentsUpload },
  props: {
    value: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    return {
      update: (updated) => emit("input", { ...props.value, ...updated }),
    };
  },
};
</script>

<style></style>
