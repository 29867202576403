<template>
  <delete-dialog-base @remove="remove" :loading="deletingContact">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-delete
        </v-icon>
      </slot>
    </template>
    <template #headline
      >Odstrániť kontaktovanie firmy {{ lazyContact.company.Name }}</template
    >
    <template #form>
      Naozaj chcete odstrániť kontaktovanie firmy
      {{ lazyContact.company.Name }}?
    </template>
  </delete-dialog-base>
</template>

<script>
import DeleteDialogBase from "./DeleteDialogBase.vue";
import {
  useDeleteOneContactMutation,
  useDeleteOneContactPilverMutation,
} from "@/generated/backend";
import { ref, watch } from "vue-demi";
export default {
  components: { DeleteDialogBase },
  props: {
    contact: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const lazyContact = ref(props.contact);
    watch(
      () => props.contact,
      (c) => (lazyContact.value = c)
    );

    const {
      mutate: removeContact,
      loading: deletingContact,
      onDone: deletingFinished,
    } = props.type === "Pilver"
      ? useDeleteOneContactPilverMutation({})
      : useDeleteOneContactMutation({});

    const remove = (cb) => {
      deletingFinished((data) => {
        cb();
        emit("removed", data);
      });
      removeContact({
        input: { id: lazyContact.value.Id },
      });
    };

    return {
      remove,
      lazyContact,
      deletingContact,
    };
  },
};
</script>

<style></style>
