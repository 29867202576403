


























import { nextTick, Ref, ref, watch } from "vue-demi";
import BaseTable from "./BaseTable.vue";
import { useResult } from "@vue/apollo-composable";
import {
  DeletedMiddlemenQuery,
  DeletedWorkersQuery,
  useDeletedMiddlemenQuery,
  useDeletedWorkersQuery,
} from "../../generated/backend";

export default {
  components: {
    BaseTable,
  },
  setup() {
    const table = ref(null);
    const fromDate = ref(null);
    const focus = ref(null);

    watch([fromDate, focus], () => {
      nextTick(() => {
        (table as any).value.searchChanged();
      });
    });

    return {
      table,
      fromDate,
      focus,
      headers: [
        {
          text: "Odstránené",
          value: "DeletedAt",
          sortable: true,
          searchable: false,
        },
        {
          text: "Meno",
          align: "start",
          value: "Name",
          searchable: true,
        },
        { text: "Telefón", value: "Phone", searchable: true },
        { text: "Typ", value: "Type", searchable: true },
        { text: "Profesia", value: "Profession", searchable: true },
        { text: "Odstránil", value: "deletedBy", searchable: true },
      ],
      query: useDeletedWorkersQuery,
      itemsGetter: (result: Ref<DeletedWorkersQuery>) =>
        useResult(result, [], (d) => d.deletedWorkers.nodes),
      totalCountGetter: (result: Ref<DeletedWorkersQuery>) =>
        useResult(result, 0, (d) => d.deletedWorkers.totalCount),
      reloadTable: () => (table as any).value.reload(),
    };
  },
};
