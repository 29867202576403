<template>
  <create-dialog-base @create="create" :loading="creatingWorker">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-btn color="primary" depressed dark class="" v-bind="attrs" v-on="on">
          Pridať nového subdodávateľa
        </v-btn>
      </slot>
    </template>
    <template #headline> Pridať nového subdodávateľa </template>
    <template #form>
      <worker-form v-model="form"></worker-form>
    </template>
  </create-dialog-base>
</template>

<script>
import { getEmptyWorker } from "@/services/utils";
import { ref } from "vue-demi";
import WorkerForm from "./Forms/WorkerForm.vue";
import CreateDialogBase from "./CreateDialogBase.vue";
import { useCreateOneWorkerMutation } from "@/generated/backend";
export default {
  components: { CreateDialogBase, WorkerForm },
  setup(props, { emit }) {
    const form = ref(getEmptyWorker());
    const {
      mutate: createWorker,
      loading: creatingWorker,
      onDone: creatingFinished,
    } = useCreateOneWorkerMutation({});

    const create = (cb) => {
      creatingFinished((data) => {
        cb();
        form.value = getEmptyWorker();
        emit("created", data);
      });
      createWorker({
        input: {
          worker: form.value,
        },
      });
    };

    return {
      form,
      create,
      creatingWorker,
    };
  },
};
</script>

<style></style>
