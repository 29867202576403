<template>
  <delete-dialog-base @remove="remove" :loading="deletingMiddleman">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-delete
        </v-icon>
      </slot>
    </template>
    <template #headline
      >Odstrániť sprostredkovateľa {{ lazyMiddleman.Name }}</template
    >
    <template #form>
      Naozaj chcete odstrániť sprostredkovateľa {{ lazyMiddleman.Name }}?
    </template>
  </delete-dialog-base>
</template>

<script>
import DeleteDialogBase from "./DeleteDialogBase.vue";
import { useDeleteOneMiddlemanMutation } from "@/generated/backend";
import { ref, watch } from "vue-demi";
export default {
  components: { DeleteDialogBase },
  props: {
    middleman: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const lazyMiddleman = ref(props.middleman);
    watch(
      () => props.middleman,
      (c) => (lazyMiddleman.value = c)
    );

    const {
      mutate: removeMiddleman,
      loading: deletingMiddleman,
      onDone: deletingFinished,
    } = useDeleteOneMiddlemanMutation({});

    const remove = (cb) => {
      deletingFinished((data) => {
        cb();
        emit("removed", data);
      });
      removeMiddleman({
        input: { id: lazyMiddleman.value.Id },
      });
    };

    return {
      remove,
      lazyMiddleman,
      deletingMiddleman,
    };
  },
};
</script>

<style></style>
