<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-text-field
        dense
        :value="value.ContactPerson"
        @input="update({ ContactPerson: $event })"
        label="Kontaktovaná osoba"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12">
      <v-text-field
        dense
        :value="value.Email"
        @input="update({ Email: $event })"
        label="Email"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="12">
      <v-text-field
        dense
        type="tel"
        :value="value.Phone"
        @input="update({ Phone: $event })"
        label="Telefón"
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12">
      <v-textarea
        dense
        :value="value.Description"
        @input="update({ Description: $event })"
        label="Popis"
      ></v-textarea>
    </v-col>
    <v-col cols="12" sm="12">
      <interest-combobox
        dense
        :value="value.Interest"
        :type="type"
        @input="update({ Interest: $event })"
        label="Záujem"
      ></interest-combobox>
    </v-col>
  </v-row>
</template>

<script>
import User from "../../../services/auth";
import { ref, watch } from "vue-demi";
import InterestCombobox from "../../Autocomplete/InterestCombobox.vue";

export default {
  components: { InterestCombobox },
  props: {
    value: { type: Object },
    type: { type: String },
  },
  setup(props, { emit }) {
    const lazyValue = ref(props.value);
    watch(
      () => props.value,
      (val) => {
        lazyValue.value = val;
        console.log(val);
      }
    );
    const update = (updated) => {
      lazyValue.value = { ...lazyValue.value, ...updated };
      emit("input", lazyValue.value);
    };

    const onNewCompanyCreated = (company) => {
      update({ company: { Id: company.Id, Name: company.Name } });
    };

    return {
      update,
      User,
      onNewCompanyCreated,
    };
  },
};
</script>

<style></style>
