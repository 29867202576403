<template>
  <v-snackbar v-model="show" multi-line color="error" right bottom>
    {{ notification.title }}

    {{ notification.message }}

    <template v-slot:action="{ attrs }">
      <v-btn dark icon text v-bind="attrs" @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { ref } from "vue-demi";
import Notification from "../services/Notification";

export default {
  components: {},
  props: {
    notification: {
      type: Notification,
    },
  },
  setup(props) {
    const show = ref(true);
    return { show };
  },
};
</script>

<style></style>
