<template>
  <edit-dialog-base
    @update="update"
    :loading="creatingProjectChange"
    ref="dialog"
  >
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-pencil
        </v-icon>
      </slot>
    </template>
    <template #headline
      >Upraviť detaily projektu
      {{ projectChange ? projectChange.project.Name : project.Name }}</template
    >
    <template #form>
      <project-change-form v-model="lazyProjectChange"></project-change-form>
    </template>
  </edit-dialog-base>
</template>

<script>
import ProjectChangeForm from "./Forms/ProjectChangeForm.vue";
import EditDialogBase from "./EditDialogBase.vue";
import {
  useCreateOneProjectChangeMutation,
  useUpdateOneProjectChangeMutation,
} from "@/generated/backend";
import { computed, ref, watch } from "vue-demi";
import { getEmptyProjectChange } from "@/services/utils";
export default {
  components: { EditDialogBase, ProjectChangeForm },
  props: {
    projectChange: {
      type: Object,
    },
    project: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    console.log(props);

    const lazyProjectChange = ref(
      props.projectChange
        ? { ...props.projectChange, projectId: props.projectChange.project.Id }
        : { ...getEmptyProjectChange(), projectId: props.project.Id }
    );
    watch(
      () => props.projectChange,
      (c) =>
        (lazyProjectChange.value = c.project
          ? c
          : { ...c, project: props.project })
    );

    const {
      mutate: updateProjectChange,
      loading: creatingProjectChange,
      onDone: creatingFinished,
    } = useCreateOneProjectChangeMutation({});

    const dialog = ref(null);

    const update = (cb) => {
      creatingFinished((data) => {
        cb();
        emit("updated", data);
      });
      const {
        __typename,
        Id,
        CreatedAt,
        DeletedAt,
        UpdatedAt,
        project,
        modifiedBy,
        ...updateItem
      } = lazyProjectChange.value;
      updateProjectChange({ input: { projectChange: updateItem } });
    };

    return {
      update,
      dialog,
      lazyProjectChange,
      creatingProjectChange,
      open: () => dialog.value.open(),
      close: () => dialog.value.close(),
    };
  },
};
</script>

<style></style>
