<template>
  <div class="tw-max-w-7xl tw-mx-auto tw-m-8">
    <user-card
      @updated="onEdit"
      @removed="onDelete"
      :loading="loading"
      :user="user"
    ></user-card>

    <v-card class="tw-max-w-7xl tw-mx-auto tw-m-8">
      <v-tabs center-active v-model="tab" align-with-title>
        <v-tabs-slider color="red"></v-tabs-slider>
        <v-tab>Kontaktovania Pronaht</v-tab>
        <v-tab>Kontaktovania Pilver</v-tab>
        <v-tab>Projekty</v-tab>
        <v-tab>Subdodávatelia</v-tab>
        <v-tab>Sprostredkovatelia</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="tw-pt-5">
        <v-tab-item>
          <user-contacts-table
            v-if="user"
            :loading="loading"
            :user="user"
          ></user-contacts-table>
        </v-tab-item>

        <v-tab-item>
          <user-contacts-table
            v-if="user"
            type="Pilver"
            :loading="loading"
            :user="user"
          ></user-contacts-table>
        </v-tab-item>

        <v-tab-item>
          <user-project-changes-table
            v-if="user"
            :loading="loading"
            :user="user"
          ></user-project-changes-table>
        </v-tab-item>

        <v-tab-item>
          <user-workers-table
            v-if="user"
            :loading="loading"
            :user="user"
          ></user-workers-table>
        </v-tab-item>

        <v-tab-item>
          <user-middlemans-table
            v-if="user"
            :loading="loading"
            :user="user"
          ></user-middlemans-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import UserCard from "@/components/Cards/UserCard.vue";
import { useUserQuery } from "@/generated/backend";
import router from "@/router";
import { useResult } from "@vue/apollo-composable";
import UserContactsTable from "@/components/Tables/UserContactsTable.vue";
import UserProjectChangesTable from "@/components/Tables/UserProjectChangesTable.vue";
import UserWorkersTable from "@/components/Tables/UserWorkersTable.vue";
import UserMiddlemansTable from "@/components/Tables/UserMiddlemansTable.vue";

export default {
  components: {
    UserCard,
    UserContactsTable,
    UserProjectChangesTable,
    UserWorkersTable,
    UserMiddlemansTable,
  },
  setup() {
    const { loading, result, refetch } = useUserQuery({
      id: router.currentRoute.params.id,
    });

    const user = useResult(result, null, (r) => r.user);

    return {
      loading,
      user,
      tab: null,
      onDelete: () => router.push({ name: "Users" }),
      onEdit: () => refetch(),
    };
  },
};
</script>

<style></style>
