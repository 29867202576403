var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"tw-mx-auto tw-max-w-3xl",attrs:{"loading":_vm.loading}},[(_vm.loading)?_c('card-loader'):[_c('div',{staticClass:"tw-px-4 tw-pb-5 tw-pt-2 sm:tw-px-6 tw-flex"},[(_vm.contact && _vm.contact.company)?_c('router-link',{staticClass:"\n          tw-text-lg\n          tw-leading-6\n          tw-font-medium\n          tw-text-gray-900\n          tw-flex\n          tw-items-center\n        ",attrs:{"to":{
          name: 'ContactDetail',
          params: { type: _vm.type, id: _vm.contact.Id },
        }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-phone-forward-outline")]),_vm._v(" Kontaktovanie "+_vm._s(_vm.name)+" z "+_vm._s(_vm.$luxon(_vm.contact.CreatedAt))+" ")],1):_c('a',{staticClass:"\n          tw-text-lg\n          tw-leading-6\n          tw-font-medium\n          tw-text-gray-900\n          tw-flex\n          tw-items-center\n        ",attrs:{"href":"#"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-phone-forward-outline")]),_vm._v(" Kontaktovanie "+_vm._s(_vm.name)+" ")],1),_c('v-spacer'),_c('edit-contact-dialog',{ref:"editContact",attrs:{"contact":_vm.contact,"company":_vm.company,"type":_vm.type},on:{"updated":function($event){return _vm.$emit('updated')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","depressed":"","color":"warning","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-pencil")])],1)]}}])})],1),_c('div',{staticClass:"tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-p-0"},[_c('dl',{staticClass:"sm:tw-divide-y sm:tw-divide-gray-200"},[(!_vm.contact)?_c('card-row',{scopedSlots:_vm._u([{key:"value",fn:function(){return [_vm._v("Žiadny kontakt")]},proxy:true}],null,false,3094628880)}):[_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Kontaktovaná osoba")]},proxy:true},{key:"value",fn:function(){return [_vm._v(_vm._s(_vm.contact.ContactPerson))]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Telefón")]},proxy:true},{key:"value",fn:function(){return [_c('a',{attrs:{"href":'tel:' + _vm.contact.Phone}},[_vm._v(_vm._s(_vm.contact.Phone))])]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Email")]},proxy:true},{key:"value",fn:function(){return [_c('a',{attrs:{"href":'mailto:' + _vm.contact.Email}},[_vm._v(_vm._s(_vm.contact.Email))])]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Záujem")]},proxy:true},{key:"value",fn:function(){return [_vm._v(_vm._s(_vm.contact.Interest))]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Popis")]},proxy:true},{key:"value",fn:function(){return [_vm._v(_vm._s(_vm.contact.Description))]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Firma")]},proxy:true},{key:"value",fn:function(){return [(_vm.contact.company)?_c('router-link',{attrs:{"to":{
                  name: 'CompanyDetail',
                  params: { id: _vm.contact.company.Id },
                }}},[_vm._v(_vm._s(_vm.contact.company.Name))]):_c('span',[_vm._v("Vymazaná")])]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Používateľ")]},proxy:true},{key:"value",fn:function(){return [_c('router-link',{attrs:{"to":{
                  name: 'UserDetail',
                  params: { id: _vm.contact.contactedBy.Id },
                }}},[_vm._v(_vm._s(_vm.contact.contactedBy.Username))])]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Pridané")]},proxy:true},{key:"value",fn:function(){return [_vm._v(_vm._s(_vm._f("luxon")(_vm.contact.CreatedAt,"ff")))]},proxy:true}])})]],2)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }