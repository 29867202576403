


























































































import { nextTick, Ref, ref, watch } from "vue-demi";
import BaseTable from "./BaseTable.vue";
import {
  CompaniesQuery,
  useCompaniesQuery,
  CompanyPilversQuery,
  useCompanyPilversQuery,
} from "@/generated/backend";
import router from "@/router";
import { useResult } from "@vue/apollo-composable";
import CreateCompanyDialog from "../Dialogs/CreateCompanyDialog.vue";
import EditCompanyDialog from "../Dialogs/EditCompanyDialog.vue";
import DeleteCompanyDialog from "../Dialogs/DeleteCompanyDialog.vue";
import Calendar from "../calendar.vue";
import CompanyFocusCombobox from "../Autocomplete/CompanyFocusCombobox.vue";
import DetailCompanyDialog from "../Dialogs/DetailCompanyDialog.vue";
import CompanyLanguageCombobox from "../Autocomplete/CompanyLanguageCombobox.vue";

export default {
  components: {
    BaseTable,
    CreateCompanyDialog,
    EditCompanyDialog,
    DeleteCompanyDialog,
    Calendar,
    CompanyFocusCombobox,
    DetailCompanyDialog,
    CompanyLanguageCombobox,
  },
  props: {
    type: { type: String },
  },
  setup(props) {
    const table = ref(null);
    const fromDate = ref(null);
    const focus = ref(null);
    const language = ref(null);
    const viewItem = ref(null);

    watch([fromDate, focus, language], () => {
      nextTick(() => {
        (table as any).value.searchChanged();
      });
    });

    const searchFunc = (s) => ({
      contact: {
        Interest: {
          iLike: `%${s}%`,
        },
      },
    });

    return {
      table,
      fromDate,
      focus,
      language,
      viewItem,
      headers: [
        {
          text: "Aktualizované",
          value: "UpdatedAt",
          sortable: true,
          searchable: false,
        },
        {
          text: "Záujem",
          value: "interest",
          sortable: false,
          searchable: true,
          searchFunc: searchFunc,
        },
        {
          text: "Názov",
          align: "start",
          value: "Name",
          searchable: true,
        },
        { text: "Web", value: "Webpage", searchable: true },
        { text: "Zameranie", value: "Focus", searchable: true },
        { text: "", value: "actions", sortable: false },
      ],
      query:
        props.type === "Pilver" ? useCompanyPilversQuery : useCompaniesQuery,

      itemsGetter:
        props.type === "Pilver"
          ? (result: Ref<CompanyPilversQuery>) =>
              useResult(result, [], (d) => d.companyPilvers.nodes)
          : (result: Ref<CompaniesQuery>) =>
              useResult(result, [], (d) => d.companies.nodes),

      totalCountGetter:
        props.type === "Pilver"
          ? (result: Ref<CompanyPilversQuery>) =>
              useResult(result, 0, (d) => d.companyPilvers.totalCount)
          : (result: Ref<CompaniesQuery>) =>
              useResult(result, 0, (d) => d.companies.totalCount),
      reloadTable: () => (table as any).value.reload(),
    };
  },
};
