<template>
  <create-dialog-base @create="create" :loading="creatingMiddleman">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-btn color="primary" depressed dark class="" v-bind="attrs" v-on="on">
          Pridať nového sprostredkovateľa
        </v-btn>
      </slot>
    </template>
    <template #headline> Pridať nového sprostredkovateľa </template>
    <template #form>
      <middleman-form v-model="form"></middleman-form>
    </template>
  </create-dialog-base>
</template>

<script>
import { getEmptyMiddleman } from "@/services/utils";
import { ref } from "vue-demi";
import MiddlemanForm from "./Forms/MiddlemanForm.vue";
import CreateDialogBase from "./CreateDialogBase.vue";
import { useCreateOneMiddlemanMutation } from "@/generated/backend";
export default {
  components: { CreateDialogBase, MiddlemanForm },
  setup(props, { emit }) {
    const form = ref(getEmptyMiddleman());
    const {
      mutate: createMiddleman,
      loading: creatingMiddleman,
      onDone: creatingFinished,
    } = useCreateOneMiddlemanMutation({});

    const create = (cb) => {
      creatingFinished((data) => {
        cb();
        form.value = getEmptyMiddleman();
        emit("created", data);
      });
      createMiddleman({
        input: {
          middleman: form.value,
        },
      });
    };

    return {
      form,
      create,
      creatingMiddleman,
    };
  },
};
</script>

<style></style>
