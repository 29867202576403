



































import { Ref, ref } from "vue-demi";
import BaseTable from "./BaseTable.vue";
import { CarsQuery, useCarQuery, useCarsQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import AttachmentViewer from "../AttachmentViewer.vue";
import EditCarDialog from "../Dialogs/EditCarDialog.vue";
import CreateCarDialog from "../Dialogs/CreateCarDialog.vue";
import DeleteCarDialog from "../Dialogs/DeleteCarDialog.vue";

export default {
  components: {
    BaseTable,
    AttachmentViewer,
    CreateCarDialog,
    EditCarDialog,
    DeleteCarDialog,
  },
  setup() {
    const table = ref(null);

    return {
      table,
      headers: [
        {
          text: "Dátum",
          value: "UpdatedAt",
          sortable: true,
          searchable: false,
        },
        { text: "Názov", value: "Name", searchable: true },
        { text: "ŠPZ", value: "Evidence", searchable: true },
        { text: "Popis", value: "Description", searchable: true },
        {
          text: "Prílohy",
          value: "attachments",
          searchable: true,
          searchFunc: (s) => ({
            attachments: {
              Name: {
                iLike: `%${s}%`,
              },
            },
          }),
          sortable: false,
          width: "30%",
        },
        { text: "", value: "actions", sortable: false },
      ],
      useUrlAttachment: "carAttachment",
      useQuery: useCarsQuery,
      itemsGetter: (result: Ref<CarsQuery>) =>
        useResult(result, [], (d) => d.cars.nodes),
      totalCountGetter: (result: Ref<CarsQuery>) =>
        useResult(result, 0, (d) => d.cars.totalCount),
      reloadTable: () => (table as any).value.reload(),
      backendUrl: window._env_.BACKEND_URL,
    };
  },
};
