import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueApollo from "vue-apollo";
import apolloClient from "./graphql";
import VueCompositionAPI from "@vue/composition-api";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { provide } from "@vue/composition-api";
import "./assets/style.css";
import VueLuxon from "vue-luxon";

Vue.use(VueLuxon, {
  output: { locale: "sk" },
});
Vue.use(VueCompositionAPI);
Vue.use(VueApollo);
Vue.config.productionTip = false;

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

new Vue({
  router,
  vuetify,
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
