var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{ref:"table",attrs:{"headers":_vm.headers,"query":_vm.useQuery,"itemsGetter":_vm.itemsGetter,"totalCountGetter":_vm.totalCountGetter,"tableTitle":"Vozidlá","defaultFilter":{
    Type: { iLike: _vm.tableType },
  }},scopedSlots:_vm._u([{key:"attachments",fn:function(ref){
  var item = ref.item;
return _vm._l((item.attachments),function(attachment){return _c('attachment-viewer',{key:attachment.Name,attrs:{"url":(_vm.backendUrl + "/" + _vm.useUrlAttachment + "/" + (item.Id) + "/" + (attachment.Name)),"name":attachment.Name}})})}},{key:"UpdatedAt",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$luxon(item.UpdatedAt))+" ")]}},{key:"create-dialog",fn:function(){return [_c('create-car-dialog',{on:{"created":_vm.reloadTable}})]},proxy:true},{key:"edit-dialog",fn:function(ref){
  var item = ref.item;
return [_c('edit-car-dialog',{attrs:{"car":item},on:{"updated":_vm.reloadTable}})]}},{key:"remove-dialog",fn:function(ref){
  var item = ref.item;
return [_c('delete-car-dialog',{attrs:{"car":item},on:{"removed":_vm.reloadTable}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }