






























import { Ref, ref } from "vue-demi";
import BaseTable from "./BaseTable.vue";
import { UsersQuery, useUsersQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import CreateUserDialog from "../Dialogs/CreateUserDialog.vue";
import EditUserDialog from "../Dialogs/EditUserDialog.vue";
import DeleteUserDialog from "../Dialogs/DeleteUserDialog.vue";
import router from "@/router";

export default {
  components: { BaseTable, CreateUserDialog, EditUserDialog, DeleteUserDialog },
  setup() {
    const table = ref(null);

    return {
      table,
      headers: [
        {
          text: "Užívateľské meno",
          align: "start",
          value: "Username",
          searchable: true,
        },
        { text: "Email", value: "Email", searchable: true },
        { text: "Status", value: "Status", searchable: true },
        { text: "Rola", value: "Role", searchable: true },
        { text: "", value: "actions", sortable: false },
      ],
      useUsersQuery,
      itemsGetter: (result: Ref<UsersQuery>) =>
        useResult(result, [], (d) => d.users.nodes),
      totalCountGetter: (result: Ref<UsersQuery>) =>
        useResult(result, 0, (d) => d.users.totalCount),
      reloadTable: () => (table as any).value.reload(),
      onRowClick: (item) =>
        router.push({ name: "UserDetail", params: { id: item.Id } }),
    };
  },
};
