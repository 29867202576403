<template>
  <combobox-base
    @input="$emit('input', $event)"
    :query="query"
    :itemsGetter="itemsGetter"
    :value="value"
    label="Názov projektu"
  ></combobox-base>
</template>

<script>
import { useProjectsQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import ComboboxBase from "./ComboboxBase.vue";
export default {
  components: { ComboboxBase },
  props: {
    value: { type: String },
  },
  setup() {
    return {
      query: (v) => useProjectsQuery(),
      itemsGetter: (result) =>
        useResult(result, [], (r) =>
          r.projects.nodes ? r.projects.nodes.map((n) => n.Name) : []
        ),
    };
  },
};
</script>
<style></style>
