var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"tw-mx-auto tw-max-w-3xl",attrs:{"loading":_vm.loading}},[(_vm.loading)?_c('card-loader'):[_c('div',{staticClass:"tw-px-4 tw-pb-5 tw-pt-2 sm:tw-px-6 tw-flex"},[_c('router-link',{staticClass:"\n          tw-text-lg\n          tw-leading-6\n          tw-font-medium\n          tw-text-gray-900\n          tw-flex\n          tw-items-center\n        ",attrs:{"to":{ name: 'CompanyDetail', params: { id: _vm.company.Id } }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-domain")]),_vm._v(" Firma "+_vm._s(_vm.company.Name)+" ")],1),_c('v-spacer'),_c('edit-company-dialog',{attrs:{"company":_vm.company,"type":_vm.type},on:{"updated":function($event){return _vm.$emit('updated')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","depressed":"","color":"warning","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-pencil")])],1)]}}])}),_c('delete-company-dialog',{attrs:{"company":_vm.company,"type":_vm.type},on:{"removed":function($event){return _vm.$emit('removed')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"fab":"","depressed":"","color":"error","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-delete")])],1)]}}])})],1),_c('div',{staticClass:"tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-p-0"},[_c('dl',{staticClass:"sm:tw-divide-y sm:tw-divide-gray-200"},[_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Názov")]},proxy:true},{key:"value",fn:function(){return [_vm._v(_vm._s(_vm.company.Name))]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Adresa")]},proxy:true},{key:"value",fn:function(){return [_vm._v(_vm._s(_vm.company.Address))]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Zameranie")]},proxy:true},{key:"value",fn:function(){return [_vm._v(_vm._s(_vm.company.Focus))]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Web")]},proxy:true},{key:"value",fn:function(){return [_c('a',{attrs:{"href":_vm.company.Webpage}},[_vm._v(_vm._s(_vm.company.Webpage))])]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Jazyk")]},proxy:true},{key:"value",fn:function(){return [_vm._v(_vm._s(_vm.company.Language))]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Aktualizované")]},proxy:true},{key:"value",fn:function(){return [_vm._v(_vm._s(_vm._f("luxon")(_vm.company.UpdatedAt)))]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Vytvorené")]},proxy:true},{key:"value",fn:function(){return [_vm._v(_vm._s(_vm._f("luxon")(_vm.company.CreatedAt)))]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Vytvoril")]},proxy:true},{key:"value",fn:function(){return [(_vm.company.createdBy)?_c('router-link',{attrs:{"to":{
                name: 'UserDetail',
                params: { id: _vm.company.createdBy.Id },
              }}},[_vm._v(_vm._s(_vm.company.createdBy.Username))]):_c('span',[_vm._v("Vymazaný")])]},proxy:true}])})],1)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }