<template>
  <v-combobox
    :value="value"
    @update:search-input="$emit('input', $event)"
    @input="$emit('input', $event)"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    label="Názov firmy"
    hide-details
    dense
    clearable
  ></v-combobox>
</template>

<script>
import { useCompaniesQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import { ref, watch } from "vue-demi";
import { useCompanyPilversQuery } from "../../generated/backend";

export default {
  props: {
    value: { type: String },
    default: {
      type: [Object, String],
      default: null,
    },
    type: { type: String },
  },
  setup(props, { emit }) {
    const search = ref(null);
    const { result, loading, refetch } =
      props.type === "Pilver"
        ? useCompanyPilversQuery({
            filter: props.defaultFilter,
          })
        : useCompaniesQuery({
            filter: props.defaultFilter,
          });

    const items =
      props.type === "Pilver"
        ? useResult(result, [], (r) =>
            r.companyPilvers.nodes
              ? r.companyPilvers.nodes.map((n) => n.Name)
              : []
          )
        : useResult(result, [], (r) =>
            r.companies.nodes ? r.companies.nodes.map((n) => n.Name) : []
          );

    watch(
      () => props.default,
      (val) => {
        if (props.default && props.default.Id !== props.value) {
          items.value.push(props.default);
          emit("input", props.default.Id);
        }
      }
    );

    watch(search, (val) => {
      // Lazily load input items
      refetch({ filter: { Name: { iLike: `%${val}%` } } });
    });

    return { search, items, loading };
  },
};
</script>
<style></style>
