var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{ref:"table",attrs:{"headers":_vm.headers,"query":_vm.useMiddlemenQuery,"itemsGetter":_vm.itemsGetter,"totalCountGetter":_vm.totalCountGetter,"tableTitle":"Sprostredkovatelia"},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"Name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'MiddlemanDetail', params: { id: item.Id } }}},[_vm._v(_vm._s(item.Name))])]}},{key:"Attachments",fn:function(ref){
var item = ref.item;
return [(!!item.attachments.length)?_c('v-chip',{attrs:{"link":"","outlined":!_vm.viewAttachments.includes(item.Id)},on:{"click":[function($event){return _vm.showAttachementClick(item)},function($event){$event.stopPropagation();}]}},[_vm._v(" "+_vm._s(_vm.viewAttachments.includes(item.Id) ? "Schovať" : "Zobraziť")+" ")]):_vm._e(),(_vm.viewAttachments.includes(item.Id))?_c('v-card',{on:{"click":function($event){$event.stopPropagation();}}},_vm._l((item.attachments),function(attachment){return _c('attachment-viewer',{key:attachment.Name,attrs:{"url":(_vm.backendUrl + "/middlemanAttachment/" + (item.Id) + "/" + (attachment.Name)),"name":attachment.Name}})}),1):_vm._e()]}},{key:"UpdatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$luxon(item.UpdatedAt))+" ")]}},{key:"create-dialog",fn:function(){return [_c('create-middleman-dialog',{on:{"created":_vm.reloadTable}})]},proxy:true},{key:"edit-dialog",fn:function(ref){
var item = ref.item;
return [_c('edit-middleman-dialog',{attrs:{"middleman":item},on:{"updated":_vm.reloadTable}})]}},{key:"remove-dialog",fn:function(ref){
var item = ref.item;
return [_c('delete-middleman-dialog',{attrs:{"middleman":item},on:{"removed":_vm.reloadTable}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }