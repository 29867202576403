<template>
  <dialog-base v-model="dialog">
    <template #activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>
    <template #headline>
      <slot name="headline"></slot>
    </template>
    <template #form>
      <slot name="form"></slot>
    </template>
    <template #actions>
      <v-spacer></v-spacer>
      <v-btn :disabled="loading" color="secondary" text @click="dialog = false">
        Zrušiť
      </v-btn>
      <v-btn :loading="loading" color="primary" text @click="update">
        Uložiť
      </v-btn>
    </template>
  </dialog-base>
</template>

<script>
import { ref } from "vue-demi";
import DialogBase from "./DialogBase.vue";
export default {
  components: { DialogBase },
  props: {
    loading: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const dialog = ref(false);
    const update = () => {
      emit("update", () => (dialog.value = false));
    };
    return {
      dialog,
      update,
      open: () => (dialog.value = true),
      close: () => (dialog.value = false),
    };
  },
};
</script>

<style></style>
