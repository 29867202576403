<template>
  <combobox-base
    @input="$emit('input', $event)"
    :query="query"
    :itemsGetter="itemsGetter"
    :value="value"
    label="Profesia"
  ></combobox-base>
</template>

<script>
import {
  useWorkerProfessionQuery,
  WorkerProfessionQuery,
} from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import ComboboxBase from "./ComboboxBase.vue";
export default {
  components: { ComboboxBase },
  props: {
    value: { type: String },
  },
  setup() {
    return {
      query: (v) => useWorkerProfessionQuery(),
      itemsGetter: (result) => useResult(result, [], (d) => d.workerProfession),
    };
  },
};
</script>
<style></style>
