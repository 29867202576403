<template>
  <delete-dialog-base @remove="remove" :loading="deletingprojectChange">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-delete
        </v-icon>
      </slot>
    </template>
    <template #headline
      >Odstrániť stav projektu {{ lazyProjectChange.project.Name }}</template
    >
    <template #form>
      Naozaj chcete odstrániť stav projektu
      {{ lazyProjectChange.project.Name }}?
    </template>
  </delete-dialog-base>
</template>

<script>
import DeleteDialogBase from "./DeleteDialogBase.vue";
import { useDeleteOneProjectChangeMutation } from "@/generated/backend";
import { ref, watch } from "vue-demi";
export default {
  components: { DeleteDialogBase },
  props: {
    projectChange: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const lazyProjectChange = ref(props.projectChange);
    watch(
      () => props.projectChange,
      (c) => (lazyProjectChange.value = c)
    );

    const {
      mutate: removeprojectChange,
      loading: deletingprojectChange,
      onDone: deletingFinished,
    } = useDeleteOneProjectChangeMutation({});

    const remove = (cb) => {
      deletingFinished((data) => {
        cb();
        emit("removed", data);
      });
      removeprojectChange({
        input: { id: lazyProjectChange.value.Id },
      });
    };

    return {
      remove,
      lazyProjectChange,
      deletingprojectChange,
    };
  },
};
</script>

<style></style>
