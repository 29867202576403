import { useMeQuery, User as UserType } from "@/generated/backend";
import router from "@/router";
import { provideApolloClient, useResult } from "@vue/apollo-composable";
import { Ref } from "@vue/composition-api";
import { ref } from "vue-demi";
import client from "../graphql";
import VueJwtDecode from "vue-jwt-decode";

const User: Ref<UserType> = ref({} as any);
export const AccessToken = ref(localStorage.getItem("accessToken") || false);

const LoadUser = () =>
  provideApolloClient(client as any)(() => {
    if (!AccessToken.value) return Promise.resolve({});

    const { onResult, onError } = useMeQuery(
      {},
      { enabled: AccessToken as any }
    );
    return new Promise((resolve, reject) => {
      onResult((data) => {
        if (data.data.me) {
          console.log(data.data.me);
          const jwtToken = VueJwtDecode.decode(AccessToken.value);
          const jwtIat = new Date(jwtToken.iat * 1000);
          const updated = new Date(data.data.me.UpdatedAt);
          updated.setHours(updated.getHours() + 1);
          if (updated > jwtIat) {
            reject(jwtIat);
          }
          resolve(data.data.me);
        } else {
          reject(data);
        }
      });
      onError((error) => reject(error));
    });
  });

export const Login = async (accessToken: string) => {
  localStorage.setItem("accessToken", accessToken);
  AccessToken.value = accessToken;
  User.value = (await LoadUser()) as any;
};

export const Logout = () => {
  localStorage.removeItem("accessToken");
  AccessToken.value = false;
  localStorage.clear();
  client.resetStore();
  router.push("/login");
  User.value = {} as any;
};

LoadUser()
  .then((u: any) => (User.value = u))
  .catch(Logout);

export default User;
