<template>
  <edit-dialog-base @update="update" :loading="creatingContract">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-pencil
        </v-icon>
      </slot>
    </template>
    <template #headline>Upraviť zmluvu s {{ lazyWorker.Name }}</template>
    <template #form>
      <contract-form v-model="lazyWorker"></contract-form>
    </template>
  </edit-dialog-base>
</template>

<script>
import EditDialogBase from "./EditDialogBase.vue";
import { useUpdateOneContractMutation } from "@/generated/backend";
import { ref, watch, watchEffect } from "vue-demi";
import ContractForm from "./Forms/ContractForm.vue";
import { useUpdateOneContractPilverMutation } from "../../generated/backend";
export default {
  components: { EditDialogBase, ContractForm },
  props: {
    contract: {
      type: Object,
    },
    contractType: {
      type: String,
    },
    companyType: {
      type: String,
    },
  },

  setup(props, { emit }) {
    const lazyWorker = ref(props.contract);
    watch(
      () => props.contract,
      (c) => (lazyWorker.value = c)
    );

    const {
      mutate: updateContract,
      loading: creatingContract,
      onDone: creatingFinished,
    } = props.companyType === "Pilver"
      ? useUpdateOneContractPilverMutation({})
      : useUpdateOneContractMutation({});

    const update = (cb) => {
      creatingFinished((data) => {
        cb();
        emit("updated", data);
      });
      const {
        __typename,
        Id,
        CreatedAt,
        DeletedAt,
        UpdatedAt,
        createdBy,
        ...updateItem
      } = lazyWorker.value;
      updateItem.attachments = updateItem.attachments.map((a) => {
        const { UpdatedAt, CreatedAt, DeletedAt, __typename, ...attachment } =
          a;
        return attachment;
      });
      updateContract({
        input: { update: updateItem, id: lazyWorker.value.Id },
      });
    };

    return {
      update,
      lazyWorker,
      creatingContract,
    };
  },
};
</script>

<style></style>
