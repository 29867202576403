






































import { Ref, ref } from "vue-demi";
import BaseTable from "./BaseTable.vue";
import {
  ProjectChangesQuery,
  useProjectChangesQuery,
} from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import EditProjectChangeDialog from "../Dialogs/EditProjectChangeDialog.vue";
import DeleteProjectChangeDialog from "../Dialogs/DeleteProjectChangeDialog.vue";
import router from "@/router";

export default {
  components: {
    BaseTable,
    EditProjectChangeDialog,
    DeleteProjectChangeDialog,
  },
  props: {
    user: {
      type: Object,
    },
  },
  setup(props) {
    const table = ref(null);

    return {
      table,
      headers: [
        {
          text: "Dátum kontaktovania",
          align: "start",
          value: "UpdatedAt",
          searchable: false,
        },
        {
          text: "Projekt",
          value: "project",
          searchable: true,
          searchFunc: (s) => ({
            project: {
              Name: {
                iLike: `%${s}%`,
              },
            },
          }),
        },
        {
          text: "Vedúci",
          value: "Manager",
          searchable: false,
          sortable: false,
        },
        { text: "Stav", value: "State", searchable: true, sortable: true },
        {
          text: "Popis",
          value: "Description",
          searchable: true,
          sortable: true,
        },
        { text: "", value: "actions", sortable: false },
      ],

      useProjectChangesQuery,
      itemsGetter: (result: Ref<ProjectChangesQuery>) =>
        useResult(result, [], (d) => d.projectChanges.nodes),
      totalCountGetter: (result: Ref<ProjectChangesQuery>) =>
        useResult(result, 0, (d) => d.projectChanges.totalCount),
      reloadTable: () => (table as any).value.reload(),
      onRowClick: (item) =>
        router.push({ name: "ProjectChangeDetail", params: { id: item.Id } }),
    };
  },
};
