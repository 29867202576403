
























































import { nextTick, Ref, ref, watch } from "vue-demi";
import BaseTable from "./BaseTable.vue";
import {
  ContractsQuery,
  useContractsQuery,
  ContractPilversQuery,
  useContractPilversQuery,
} from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import AttachmentViewer from "../AttachmentViewer.vue";
import CreateContractDialog from "../Dialogs/CreateContractDialog.vue";
import EditContractDialog from "../Dialogs/EditContractDialog.vue";
import DeleteContractDialog from "../Dialogs/DeleteContractDialog.vue";
import ContractYearCombobox from "../Autocomplete/ContractYearCombobox.vue";

export default {
  components: {
    BaseTable,
    AttachmentViewer,
    CreateContractDialog,
    EditContractDialog,
    DeleteContractDialog,
    ContractYearCombobox,
  },
  props: {
    tableType: { type: String },
    companyType: { type: String },
  },
  setup(props) {
    const table = ref(null);
    const year = ref(null);

    watch([year], () => {
      nextTick(() => {
        (table as any).value.searchChanged();
      });
    });

    return {
      table,
      year,
      headers: [
        {
          text: "Dátum",
          value: "UpdatedAt",
          sortable: true,
          searchable: false,
        },
        { text: "Názov", value: "Name", searchable: true },
        {
          text: "Popis",
          value: "Description",
          searchable: true,
          sortable: false,
        },
        { text: "Rok", value: "Year", searchable: true, width: "10%" },
        {
          text: "Prílohy",
          value: "attachments",
          searchable: true,
          searchFunc: (s) => ({
            attachments: {
              Name: {
                iLike: `%${s}%`,
              },
            },
          }),
          sortable: false,
          width: "50%",
        },
        { text: "", value: "actions", sortable: false },
      ],
      useUrlAttachment:
        props.companyType === "Pilver"
          ? "contractPilverAttachment"
          : "contractAttachment",
      useQuery:
        props.companyType === "Pilver"
          ? useContractPilversQuery
          : useContractsQuery,
      itemsGetter:
        props.companyType === "Pilver"
          ? (result: Ref<ContractPilversQuery>) =>
              useResult(result, [], (d) => d.contractPilvers.nodes)
          : (result: Ref<ContractsQuery>) =>
              useResult(result, [], (d) => d.contracts.nodes),
      totalCountGetter:
        props.companyType === "Pilver"
          ? (result: Ref<ContractPilversQuery>) =>
              useResult(result, 0, (d) => d.contractPilvers.totalCount)
          : (result: Ref<ContractsQuery>) =>
              useResult(result, 0, (d) => d.contracts.totalCount),
      reloadTable: () => (table as any).value.reload(),
      backendUrl: window._env_.BACKEND_URL,
    };
  },
};
