<template>
  <div>
    <notification
      v-for="(notification, id) of notifications"
      :key="id"
      :notification="notification"
    >
    </notification>
  </div>
</template>

<script>
import NotificationService from "../services/NotificationsService";
import Notification from "./Notification.vue";
export default {
  components: {
    Notification,
  },
  setup() {
    return {
      notifications: NotificationService.notifications,
    };
  },
};
</script>

<style></style>
