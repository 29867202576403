<template>
  <edit-dialog-base @update="update" :loading="updatingCompany">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-pencil
        </v-icon>
      </slot>
    </template>
    <template #headline>Upraviť firmu {{ lazyCompany.Name }}</template>
    <template #form>
      <company-form v-model="lazyCompany"></company-form>
    </template>
  </edit-dialog-base>
</template>

<script>
import CompanyForm from "./Forms/CompanyForm.vue";
import EditDialogBase from "./EditDialogBase.vue";
import {
  useUpdateOneCompanyMutation,
  useUpdateOneCompanyPilverMutation,
} from "@/generated/backend";
import { ref, watch, watchEffect } from "vue-demi";
export default {
  components: { EditDialogBase, CompanyForm },
  props: {
    company: {
      type: Object,
    },
    type: { type: String },
  },

  setup(props, { emit }) {
    const lazyCompany = ref(props.company);
    watch(
      () => props.company,
      (c) => (lazyCompany.value = c)
    );

    const {
      mutate: updateCompany,
      loading: updatingCompany,
      onDone: updatingFinished,
    } = props.type === "Pilver"
      ? useUpdateOneCompanyPilverMutation()
      : useUpdateOneCompanyMutation();

    const update = (cb) => {
      updatingFinished((data) => {
        cb();
        emit("updated", data);
      });
      const {
        __typename,
        Id,
        CreatedAt,
        DeletedAt,
        UpdatedAt,
        contact,
        histories,
        createdBy,
        ...updateItem
      } = lazyCompany.value;
      updateCompany({
        input: { update: updateItem, id: lazyCompany.value.Id },
      });
    };

    return {
      update,
      lazyCompany,
      updatingCompany,
    };
  },
};
</script>

<style></style>
