<template>
  <projects-table class="tw-max-w-7xl tw-mx-auto tw-m-8"></projects-table>
</template>

<script>
import ProjectsTable from "@/components/Tables/ProjectsTable.vue";

export default {
  components: { ProjectsTable },
};
</script>

<style></style>
