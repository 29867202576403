var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"tw-mx-auto tw-max-w-3xl",attrs:{"loading":_vm.loading}},[(_vm.loading)?_c('card-loader'):[_c('div',{staticClass:"tw-px-4 tw-pb-5 tw-pt-2 sm:tw-px-6 tw-flex"},[(_vm.projectChange && _vm.projectChange.project)?_c('router-link',{staticClass:"\n          tw-text-lg\n          tw-leading-6\n          tw-font-medium\n          tw-text-gray-900\n          tw-flex\n          tw-items-center\n        ",attrs:{"to":{
          name: 'ProjectChangeDetail',
          params: { id: _vm.projectChange.Id },
        }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-phone-forward-outline")]),_vm._v(" Podrobnosti "+_vm._s(_vm.name)+" z "+_vm._s(_vm.$luxon(_vm.projectChange.CreatedAt))+" ")],1):_c('a',{staticClass:"\n          tw-text-lg\n          tw-leading-6\n          tw-font-medium\n          tw-text-gray-900\n          tw-flex\n          tw-items-center\n        ",attrs:{"href":"#"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-phone-forward-outline")]),_vm._v(" Podrobnosti "+_vm._s(_vm.name)+" ")],1),_c('v-spacer'),_c('edit-project-change-dialog',{ref:"editProjectChange",attrs:{"projectChange":_vm.projectChange,"project":_vm.project},on:{"updated":function($event){return _vm.$emit('updated')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","depressed":"","color":"warning","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-pencil")])],1)]}}])})],1),_c('div',{staticClass:"tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-p-0"},[_c('dl',{staticClass:"sm:tw-divide-y sm:tw-divide-gray-200"},[(!_vm.projectChange)?_c('card-row',{scopedSlots:_vm._u([{key:"value",fn:function(){return [_vm._v("Žiadny stav")]},proxy:true}],null,false,3633588672)}):[_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Popis")]},proxy:true},{key:"value",fn:function(){return [_c('pre',[_vm._v(_vm._s(_vm.projectChange.Description))])]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Stav")]},proxy:true},{key:"value",fn:function(){return [_vm._v(_vm._s(_vm.projectChange.State))]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Projekt")]},proxy:true},{key:"value",fn:function(){return [(_vm.projectChange.project)?_c('router-link',{attrs:{"to":{
                  name: 'ProjectDetail',
                  params: { id: _vm.projectChange.project.Id },
                }}},[_vm._v(_vm._s(_vm.projectChange.project.Name))]):_c('span',[_vm._v("Vymazaný")])]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Používateľ")]},proxy:true},{key:"value",fn:function(){return [_c('router-link',{attrs:{"to":{
                  name: 'UserDetail',
                  params: { id: _vm.projectChange.modifiedBy.Id },
                }}},[_vm._v(_vm._s(_vm.projectChange.modifiedBy.Username))])]},proxy:true}])}),_c('card-row',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Pridané")]},proxy:true},{key:"value",fn:function(){return [_vm._v(_vm._s(_vm._f("luxon")(_vm.projectChange.CreatedAt)))]},proxy:true}])})]],2)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }