<template>
  <dialog-base v-model="dialog">
    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <template #headline
      >Firma {{ lazyCompany ? lazyCompany.Name : "" }}</template
    >
    <template #form>
      <div class="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-p-0">
        <dl class="sm:tw-divide-y sm:tw-divide-gray-200">
          <card-row>
            <template #title>Meno</template>
            <template #value>{{
              contacted ? lazyCompany.contact.ContactPerson : "Nekontaktovana"
            }}</template>
          </card-row>
          <card-row>
            <template #title>Telefón</template>
            <template #value>{{
              contacted ? lazyCompany.contact.Phone : "Nekontaktovana"
            }}</template>
          </card-row>
          <card-row>
            <template #title>E-mail</template>
            <template #value>{{
              contacted ? lazyCompany.contact.Email : "Nekontaktovana"
            }}</template>
          </card-row>
          <card-row>
            <template #title>Dátum</template>
            <template #value>{{
              contacted && lazyCompany.contact.UpdatedAt | luxon("ff")
            }}</template>
          </card-row>
          <card-row>
            <template #title>Záujem</template>
            <template #value>{{
              contacted ? lazyCompany.contact.Interest : "Nekontaktovana"
            }}</template>
          </card-row>
          <card-row>
            <template #title>Popis kontaktovania</template>
            <template #value>{{
              contacted ? lazyCompany.contact.Description : "Nekontaktovana"
            }}</template>
          </card-row>
          <card-row>
            <template #title>Kontaktoval /a</template>
            <template #value>{{
              contacted
                ? lazyCompany.contact.contactedBy.Username
                : "Nekontaktovana"
            }}</template>
          </card-row>
          <card-row>
            <template #title>Kontaktovať</template>
            <template #value>
              <create-contact-dialog
                @updated="updated"
                :company="lazyCompany"
                :type="type"
              >
              </create-contact-dialog>
            </template>
          </card-row>
        </dl>
      </div>
    </template>
    <template #actions>
      <v-spacer></v-spacer>
      <v-btn :disabled="loading" color="secondary" text @click="dialog = false">
        Zrušiť
      </v-btn>
      <v-btn :loading="loading" color="primary" text @click="showAllInfo">
        Zobraziť všetky informácie
      </v-btn>
    </template>
  </dialog-base>
</template>

<script>
import { nextTick, ref, watch } from "vue-demi";
import DialogBase from "./DialogBase.vue";
import CardRow from "../Cards/CardRow.vue";
import router from "@/router";
import {
  useCreateOneContactMutation,
  useCreateOneContactPilverMutation,
} from "@/generated/backend";
import CreateContactDialog from "./CreateContactDialog.vue";
import { getEmptyCompany } from "@/services/utils";
export default {
  components: { DialogBase, CardRow, CreateContactDialog },
  props: {
    loading: {
      type: Boolean,
    },
    company: { type: Object },
    type: { type: String },
  },
  setup(props, { emit }) {
    const dialog = ref(false);
    const contacted = ref(false);
    const lazyCompany = ref(props.company ? props.company : getEmptyCompany());

    watch(
      () => props.company,
      (it) => {
        if (it != null) {
          // lazyCompany.value = it;
          dialog.value = true;
          nextTick(() => {
            contacted.value = !!it.contact;
            lazyCompany.value = it;
          });
        }
      }
    );

    watch(dialog, () => {
      if (dialog.value === false) {
        emit("closed", dialog.value);
      }
    });

    const showAllInfo = () => {
      router.push({
        name: "CompanyDetail",
        params: { type: props.type, id: props.company.Id },
      });
    };

    return {
      dialog,
      updated: (data) => {
        dialog.value = false;
        emit("updated", data);
      },
      showAllInfo,
      contacted,
      lazyCompany,
    };
  },
};
</script>

<style></style>
