<template>
  <div class="tw-max-w-7xl tw-mx-auto tw-m-8">
    <users-table class="tw-max-w-7xl tw-mx-auto tw-m-8"></users-table>

    <v-card class="tw-max-w-7xl tw-mx-auto tw-m-8">
      <v-tabs center-active v-model="tab" align-with-title>
        <v-tabs-slider color="red"></v-tabs-slider>
        <v-tab>Odstránené firmy Pronaht</v-tab>
        <v-tab>Odstránené firmy Pilver</v-tab>
        <v-tab>Odstránený Subdodávatelia</v-tab>
        <v-tab>Odstránené Sprostredkovatelia</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="tw-pt-5">
        <v-tab-item>
          <deleted-companies-table type="Pronaht"></deleted-companies-table>
        </v-tab-item>
        <v-tab-item>
          <deleted-companies-table type="Pilver"></deleted-companies-table
        ></v-tab-item>
        <v-tab-item>
          <deleted-workers-table></deleted-workers-table>
        </v-tab-item>
        <v-tab-item>
          <deleted-middlemans-table></deleted-middlemans-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import UsersTable from "@/components/Tables/UsersTable.vue";
import DeletedCompaniesTable from "../components/Tables/DeletedCompaniesTable.vue";
import DeletedMiddlemansTable from "@/components/Tables/DeletedMiddlemansTable.vue";
import DeletedWorkersTable from "@/components/Tables/DeletedWorkersTable.vue";

export default {
  components: {
    UsersTable,
    DeletedCompaniesTable,
    DeletedMiddlemansTable,
    DeletedWorkersTable,
  },
  setup() {
    return {
      tab: null,
    };
  },
};
</script>

<style></style>
