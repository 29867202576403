<template>
  <iframe
    ref="iframeElement"
    :src="mattermost"
    allowfullscreen
    style="width: 100%; height: 92vh"
  >
  </iframe>
</template>

<script>
import User from "@/services/auth";
import { onMounted, ref } from "vue-demi";
export default {
  setup() {
    const iframeElement = ref(null);

    return {
      iframeElement,
      mattermost: window._env_.MATTERMOST_URL
        ? window._env_.MATTERMOST_URL
        : "https://is.pronaht.sk/chat",
    };
  },
};
</script>

<style></style>
