<template>
  <delete-dialog-base @remove="remove" :loading="deletingUser">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-delete
        </v-icon>
      </slot>
    </template>
    <template #headline>Odstrániť užívateľa {{ lazyUser.Username }}</template>
    <template #form>
      Naozaj chcete odstrániť užívateľa {{ lazyUser.Username }}?
    </template>
  </delete-dialog-base>
</template>

<script>
import DeleteDialogBase from "./DeleteDialogBase.vue";
import { useDeleteOneUserMutation } from "@/generated/backend";
import { ref, watch } from "vue-demi";
export default {
  components: { DeleteDialogBase },
  props: {
    user: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const lazyUser = ref(props.user);
    watch(
      () => props.user,
      (c) => (lazyUser.value = c)
    );

    const {
      mutate: removeUser,
      loading: deletingUser,
      onDone: deletingFinished,
    } = useDeleteOneUserMutation({});

    const remove = (cb) => {
      deletingFinished((data) => {
        cb();
        emit("removed", data);
      });
      removeUser({
        input: { id: lazyUser.value.Id },
      });
    };

    return {
      remove,
      lazyUser,
      deletingUser,
    };
  },
};
</script>

<style></style>
