var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-table',{ref:"table",attrs:{"headers":_vm.headers,"query":_vm.query,"itemsGetter":_vm.itemsGetter,"totalCountGetter":_vm.totalCountGetter,"defaultFilter":Object.assign({}, (_vm.fromDate
        ? {
            UpdatedAt: {
              gte: _vm.fromDate,
            },
          }
        : {}),
      (_vm.focus
        ? {
            Focus: {
              eq: _vm.focus,
            },
          }
        : {}),
      (_vm.language
        ? {
            Language: {
              eq: _vm.language,
            },
          }
        : {})),"tableTitle":"Firmy"},on:{"click:row":function (item) { return (_vm.viewItem = item); }},scopedSlots:_vm._u([{key:"Name",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'CompanyDetail', params: { type: _vm.type, id: item.Id } }}},[_vm._v(_vm._s(item.Name))])]}},{key:"Webpage",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":item.Webpage}},[_vm._v(_vm._s(item.Webpage))])]}},{key:"UpdatedAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$luxon(item.UpdatedAt))+" ")]}},{key:"interest",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.contact ? item.contact.Interest : "")+" ")]}},{key:"create-dialog",fn:function(){return [_c('calendar',{attrs:{"label":"Zobraziť od"},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}}),_c('company-focus-combobox',{staticClass:"tw-w-40 mx-4",attrs:{"type":_vm.type},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('company-language-combobox',{staticClass:"tw-w-40 mx-4",attrs:{"type":_vm.type},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}}),_c('create-company-dialog',{attrs:{"type":_vm.type},on:{"created":_vm.reloadTable}})]},proxy:true},{key:"edit-dialog",fn:function(ref){
    var item = ref.item;
return [_c('edit-company-dialog',{attrs:{"type":_vm.type,"company":item},on:{"updated":_vm.reloadTable}})]}},{key:"remove-dialog",fn:function(ref){
    var item = ref.item;
return [_c('delete-company-dialog',{attrs:{"type":_vm.type,"company":item},on:{"removed":_vm.reloadTable}})]}}])}),_c('detail-company-dialog',{attrs:{"company":_vm.viewItem,"type":_vm.type},on:{"closed":function($event){_vm.viewItem = null},"updated":_vm.reloadTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }