<template>
  <notifications-table
    class="tw-max-w-7xl tw-mx-auto tw-m-8"
  ></notifications-table>
</template>

<script>
import CompaniesTable from "@/components/Tables/CompaniesTable.vue";
import NotificationsTable from "@/components/Tables/NotificationsTable.vue";

export default {
  components: { NotificationsTable },
};
</script>

<style></style>
