var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{ref:"table",attrs:{"headers":_vm.headers,"query":_vm.useProjectChangesQuery,"itemsGetter":_vm.itemsGetter,"totalCountGetter":_vm.totalCountGetter,"defaultFilter":{ modifiedBy: { Id: { eq: _vm.user.Id } } },"tableTitle":("Projekty používateľa " + (_vm.user.Username))},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"UpdatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("luxon")(item.UpdatedAt,"ff"))+" ")]}},{key:"project",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.project ? item.project.Name : "Vymazaná")+" ")]}},{key:"Manager",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.project ? item.project.Manager : "Vymazaný")+" ")]}},{key:"Email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'mailto:' + item.Email}},[_vm._v(_vm._s(item.Email))])]}},{key:"edit-dialog",fn:function(ref){
var item = ref.item;
return [_c('edit-project-change-dialog',{attrs:{"projectChange":item},on:{"updated":_vm.reloadTable}})]}},{key:"remove-dialog",fn:function(ref){
var item = ref.item;
return [_c('delete-project-change-dialog',{attrs:{"projectChange":item},on:{"removed":_vm.reloadTable}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }