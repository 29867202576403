<template>
  <project-change-card
    class="tw-m-8"
    @updated="onEdit"
    @removed="onDelete"
    :loading="loading"
    :projectChange="projectchange"
  ></project-change-card>
</template>

<script>
import ProjectChangeCard from "@/components/Cards/ProjectChangeCard.vue";
import { useProjectChangeQuery } from "@/generated/backend";
import router from "@/router";
import { useResult } from "@vue/apollo-composable";

export default {
  components: { ProjectChangeCard },
  setup() {
    const { loading, result, refetch } = useProjectChangeQuery({
      id: router.currentRoute.params.id,
    });

    const projectchange = useResult(result, null, (r) => r.projectChange);

    return {
      loading,
      projectchange,
      onDelete: () => router.push({ name: "ProjectChanges" }),
      onEdit: () => refetch(),
    };
  },
};
</script>

<style></style>
