




































import AttachmentsUpload from "@/components/AttachmentsUpload.vue";
import ContractYearCombobox from "@/components/Autocomplete/ContractYearCombobox.vue";

export default {
  components: { AttachmentsUpload, ContractYearCombobox },
  props: {
    value: {
      type: Object,
    },
    type: { type: String },
  },
  setup(props, { emit }) {
    return {
      update: (updated) => emit("input", { ...props.value, ...updated }),
    };
  },
};
