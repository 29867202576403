<template>
  <v-combobox
    :value="internalValue"
    @update:search-input="$emit('input', $event)"
    @input="$emit('input', $event)"
    :item-text="itemText"
    :loading="loading"
    :items="items"
    :label="label"
    hide-details
    dense
    clearable
  ></v-combobox>
</template>

<script>
import { ref, watch } from "vue-demi";
export default {
  props: {
    label: { type: String },
    query: { type: Function },
    itemsGetter: { type: Function },
    value: {
      type: [String, Array],
    },
    default: {
      type: [Object, String],
      default: null,
    },
    itemText: { type: String },
  },
  setup(props, { emit }) {
    const internalValue = ref(props.value);

    const { result, loading, refetch } = props.query({
      filter: props.defaultFilter,
    });
    const items = props.itemsGetter(result);

    watch(
      () => props.default,
      (val) => {
        if (props.default && props.default.Id !== props.value) {
          items.value.push(props.default);
          emit("input", props.default.Id);
        }
      }
    );

    watch(
      () => props.value,
      (val) => {
        if (val != internalValue.value) {
          internalValue.value = val;
        }
      }
    );

    return {
      loading,
      items,
      internalValue,
    };
  },
};
</script>
<style></style>
