<template>
  <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="mx-2"
        :value="formattedDate"
        clearable
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        @click:clear="$emit('input', null)"
        dense
        hide-details
      ></v-text-field>
    </template>
    <v-date-picker
      :value="value"
      @input="$emit('input', $event)"
      @change="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { DateTime } from "luxon";
import { computed, ref } from "vue-demi";

export default {
  props: {
    value: {
      type: String,
      default: DateTime.local().toISODate(),
    },
    label: {
      type: String,
      default: "Dátum",
    },
  },
  setup(props) {
    const menu = ref(false);

    const formattedDate = computed(() =>
      props.value ? DateTime.fromISO(props.value).toRelative() : ""
    );

    return {
      menu,
      formattedDate,
    };
  },
};
</script>

<style></style>
