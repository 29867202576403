<template>
  <autocomplete-base
    @input="$emit('input', $event)"
    :query="query"
    :itemsGetter="itemsGetter"
    :value="value"
    label="Roľa"
  ></autocomplete-base>
</template>

<script>
import { useUserRolesQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import AutocompleteBase from "./AutocompleteBase.vue";

export default {
  components: { AutocompleteBase },
  props: {
    value: { type: String },
  },
  setup() {
    return {
      query: (v) => useUserRolesQuery(),
      itemsGetter: (result) => useResult(result, [], (d) => d.userRoles),
    };
  },
};
</script>
<style></style>
