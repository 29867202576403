<template>
  <combobox-base
    @input="$emit('input', $event)"
    :query="query"
    :itemsGetter="itemsGetter"
    :value="value"
    label="Jazyk"
  ></combobox-base>
</template>

<script>
import { useProjectLanguagesQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import ComboboxBase from "./ComboboxBase.vue";
export default {
  components: { ComboboxBase },
  props: {
    value: { type: String },
  },
  setup() {
    return {
      query: (v) => useProjectLanguagesQuery(),
      itemsGetter: (result) => useResult(result, [], (r) => r.projectLanguages),
    };
  },
};
</script>
<style></style>
