import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { sk } from "vuetify/src/locale";

Vue.use(Vuetify);

export default new Vuetify({
  preset: {
    breakpoint: {
      scrollBarWidth: 16,
      thresholds: {
        xs: 600,
        sm: 960,
        md: 1280,
        lg: 1920,
      },
    },
    icons: {
      iconfont: "mdi",
      values: {},
    },
    lang: {
      current: "sk",
      locales: { sk },
    },
    rtl: false,
    theme: {
      dark: false,
      default: "light",
      disable: false,
      options: {
        cspNonce: undefined,
        customProperties: undefined,
        minifyTheme: undefined,
        themeCache: undefined,
      },
      themes: {
        light: {
          primary: "#b91c1c",
          secondary: "#424242",
          accent: "#82B1FF",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FB8C00",

          "primary-50": "#FEF2F2",
          "primary-100": "#FEE2E2",
          "primary-200": "#FECACA",
          "primary-300": "#FCA5A5",
          "primary-400": "#F87171",
          "primary-500": "#EF4444",
          "primary-600": "#DC2626",
          "primary-700": "#B91C1C",
          "primary-800": "#991B1B",
          "primary-900": "#7F1D1D",
        },
      },
    },
  },
});
