import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import { AccessToken } from "./services/auth";

import Notification from "./services/Notification";

declare global {
  interface Window {
    _env_: any;
  }
}

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: `${window._env_.BACKEND_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: AccessToken.value ? `Bearer ${AccessToken.value}` : "",
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        new Notification({
          type: "error",
          title: "Chyba pri komunikácii so serverom!",
          message: error.message,
        }).publish();
      }
      console.error(graphQLErrors);
    }

    if (networkError) {
      new Notification({
        type: "error",
        title: "Chyba pri komunikácii so serverom!",
        message: networkError.message,
      }).publish();
      console.error(networkError);
    }
  }
);

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
export default new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink as any) as any) as any,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});
