import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import AppLayout from "../views/AppLayout.vue";
import Login from "../views/Login.vue";
import { AccessToken } from "@/services/auth";
import Companies from "../views/Companies.vue";
import Projects from "../views/Projects.vue";
import Notifications from "../views/Notifications.vue";
import Users from "../views/Users.vue";
import ContactDetail from "@/views/ContactDetail.vue";
import ProjectChangeDetail from "@/views/ProjectChangeDetail.vue";
import CompanyDetail from "@/views/CompanyDetail.vue";
import ProjectDetail from "@/views/ProjectDetail.vue";
import UserDetail from "@/views/UserDetail.vue";
import BoardView from "@/views/BoardView.vue";
import ChatView from "@/views/ChatView.vue";
import Workers from "@/views/Workers.vue";
import WorkerDetail from "@/views/WorkerDetail.vue";
import ContractsCompanies from "@/views/ContractsCompanies.vue";
import ContractsWorkers from "@/views/ContractsWorkers.vue";
import CompaniesPilverVue from "@/views/CompaniesPilver.vue";
import ContractsCompaniesPilverVue from "@/views/ContractsCompaniesPilver.vue";
import ContractsWorkersPilverVue from "@/views/ContractsWorkersPilver.vue";
import MiddlemansVue from "@/views/Middlemans.vue";
import MiddlemanDetailVue from "@/views/MiddlemanDetail.vue";
import CarsVue from "@/views/Cars.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: AppLayout,
    children: [
      {
        path: "/contact/:id",
        name: "ContactDetail",
        component: ContactDetail,
      },
      {
        path: "/Companies",
        name: "Companies",
        component: Companies,
      },
      {
        path: "/projects",
        name: "Projects",
        component: Projects,
      },
      {
        path: "/workers",
        name: "Workers",
        component: Workers,
      },
      {
        path: "/middlemans",
        name: "Middlemans",
        component: MiddlemansVue,
      },
      {
        path: "/middleman/:id",
        name: "MiddlemanDetail",
        component: MiddlemanDetailVue,
      },
      {
        path: "/notification",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "/company/:type/:id/:on?",
        name: "CompanyDetail",
        component: CompanyDetail,
      },
      {
        path: "/project/:id/:on?",
        name: "ProjectDetail",
        component: ProjectDetail,
      },
      {
        path: "/projectChange/:id",
        name: "ProjectChangeDetail",
        component: ProjectChangeDetail,
      },
      {
        path: "/worker/:id",
        name: "WorkerDetail",
        component: WorkerDetail,
      },
      {
        path: "/user",
        name: "Users",
        component: Users,
      },
      {
        path: "/user/:id",
        name: "UserDetail",
        component: UserDetail,
      },
      {
        path: "/",
        name: "Board",
        component: BoardView,
      },
      {
        path: "/chat",
        name: "Chat",
        component: ChatView,
      },
      {
        path: "/contracts_companies",
        name: "ContractsCompanies",
        component: ContractsCompanies,
      },
      {
        path: "/contracts_workers",
        name: "ContractsWorkers",
        component: ContractsWorkers,
      },
      {
        path: "/CompaniesPilver",
        name: "CompaniesPilver",
        component: CompaniesPilverVue,
      },
      {
        path: "/contracts_companies_pilver",
        name: "ContractsCompaniesPilver",
        component: ContractsCompaniesPilverVue,
      },
      {
        path: "/contracts_workers_pilver",
        name: "ContractsWorkersPilver",
        component: ContractsWorkersPilverVue,
      },
      {
        path: "/cars",
        name: "Cars",
        component: CarsVue,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !AccessToken.value) next({ name: "Login" });
  else next();
});

export default router;
