<template>
  <combobox-base
    @input="$emit('input', $event)"
    :query="query"
    :itemsGetter="itemsGetter"
    :value="value"
    label="Typ projektu"
  ></combobox-base>
</template>

<script>
import { useProjectTypesQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import ComboboxBase from "./ComboboxBase.vue";
export default {
  components: { ComboboxBase },
  props: {
    value: { type: String },
  },
  setup() {
    return {
      query: (v) => useProjectTypesQuery(),
      itemsGetter: (result) => useResult(result, [], (r) => r.projectTypes),
    };
  },
};
</script>
<style></style>
