<template>
  <workers-table class="tw-max-w-7xl tw-mx-auto tw-m-8"></workers-table>
</template>

<script>
import WorkersTable from "@/components/Tables/WorkersTable.vue";

export default {
  components: { WorkersTable },
};
</script>

<style></style>
