<template>
  <v-card class="tw-mx-auto tw-max-w-3xl" :loading="loading">
    <card-loader v-if="loading"></card-loader>
    <template v-else>
      <div class="tw-px-4 tw-pb-5 tw-pt-2 sm:tw-px-6 tw-flex">
        <router-link
          :to="{ name: 'UserDetail', params: { id: user.Id } }"
          class="
            tw-text-lg
            tw-leading-6
            tw-font-medium
            tw-text-gray-900
            tw-flex
            tw-items-center
          "
        >
          <v-icon color="primary" class="mr-2">mdi-account</v-icon>
          Užívateľ
          {{ user.Username }}
        </router-link>
        <v-spacer></v-spacer>
        <edit-user-dialog
          @updated="$emit('updated')"
          :user="user"
          v-slot:default="{ on, attrs }"
        >
          <v-btn fab depressed color="warning" small v-bind="attrs" v-on="on">
            <v-icon dense>mdi-pencil</v-icon>
          </v-btn>
        </edit-user-dialog>
        <delete-user-dialog
          @removed="$emit('removed')"
          :user="user"
          v-slot:default="{ on, attrs }"
        >
          <v-btn
            fab
            depressed
            color="error"
            small
            v-bind="attrs"
            v-on="on"
            class="ml-2"
          >
            <v-icon dense>mdi-delete</v-icon>
          </v-btn></delete-user-dialog
        >
      </div>
      <div class="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-p-0">
        <dl class="sm:tw-divide-y sm:tw-divide-gray-200">
          <card-row>
            <template #title>Meno</template>
            <template #value>{{ user.Username }}</template>
          </card-row>
          <card-row>
            <template #title>Roľa</template>
            <template #value>{{ user.Role }}</template>
          </card-row>
          <card-row>
            <template #title>Email</template>
            <template #value>{{ user.Email }}</template>
          </card-row>
          <card-row>
            <template #title>Status</template>
            <template #value>{{ user.Status }}</template>
          </card-row>
          <card-row>
            <template #title>Aktualizované</template>
            <template #value>{{ user.UpdatedAt | luxon("f") }}</template>
          </card-row>
          <card-row>
            <template #title>Vytvorené</template>
            <template #value>{{ user.CreatedAt | luxon("f") }}</template>
          </card-row>
        </dl>
      </div>
    </template>
  </v-card>
</template>

<script>
import { useUserQuery } from "@/generated/backend";
import DeleteUserDialog from "../Dialogs/DeleteUserDialog.vue";
import EditUserDialog from "../Dialogs/EditUserDialog.vue";
import CardLoader from "./CardLoader.vue";
import CardRow from "./CardRow.vue";
import { useResult } from "@vue/apollo-composable";
import router from "@/router";
export default {
  components: { CardRow, CardLoader, EditUserDialog, DeleteUserDialog },
  props: {
    loading: { type: Boolean },
    user: { type: Object },
  },
};
</script>

<style></style>
