<template>
  <edit-dialog-base @update="update" :loading="loading" ref="dialog">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-btn color="primary" v-bind="attrs" v-on="on">Kontaktovať </v-btn>
      </slot>
    </template>
    <template #headline
      >Pridať kontaktovanie firmy {{ company ? company.Name : "" }}</template
    >
    <template #form>
      <contact-form v-model="lazyContact" :type="type"></contact-form>
      <div class="tw-m-4 tw-mt-6">
        <v-checkbox
          class="tw-m-0"
          @click="chbClickPronaht"
          v-model="addPronaht"
          label="Uložiť pre Pronaht"
        ></v-checkbox>
        <v-checkbox
          class="tw-m-0"
          @click="chbClickPilver"
          v-model="addPilver"
          label="Uložiť pre PilVer"
        ></v-checkbox>
      </div>
    </template>
  </edit-dialog-base>
</template>

<script>
import ContactForm from "./Forms/ContactForm.vue";
import EditDialogBase from "./EditDialogBase.vue";
import {
  useCreateOneContactMutation,
  useCreateOneContactPilverMutation,
  useCompanyIdQuery,
  useCompanyPilverIdQuery,
} from "@/generated/backend";
import { computed, ref, watch } from "vue-demi";
import { getEmptyContact } from "@/services/utils";
import { provideApolloClient, useResult } from "@vue/apollo-composable";
import apolloClient from "../../graphql.ts";

export default {
  components: { EditDialogBase, ContactForm },
  props: {
    company: {
      type: Object,
    },
    type: { type: String },
  },

  setup(props, { emit }) {
    const lazyContact = ref(getEmptyContact());

    const addPilver = ref(props.type === "Pilver");
    const addPronaht = ref(props.type !== "Pilver");

    const pilverId = ref();
    const pronahtId = ref();

    const loading = ref(false);

    watch(
      () => props.company,
      (c) => {
        if (c != null) {
          console.log(c);
          if (c.contact) {
            lazyContact.value = { ...c.contact, companyId: c.Id };
          } else {
            lazyContact.value = { ...getEmptyContact(), companyId: c.Id };
          }
        }
      },
      { deep: true }
    );

    provideApolloClient(apolloClient);

    const dialog = ref(null);

    const createContactPronaht = useCreateOneContactMutation({});
    const createContactPilver = useCreateOneContactPilverMutation({});

    const update = (cb) => {
      const {
        __typename,
        Id,
        CreatedAt,
        DeletedAt,
        UpdatedAt,
        company,
        contactedBy,
        ...updateItem
      } = lazyContact.value;

      // save to Pronaht
      if (addPronaht.value === true) {
        const { ...pronahtContact } = updateItem;
        if (props.type === "Pilver") {
          pronahtContact.companyId = pronahtId.value;
        }

        createContactPronaht.onDone((data) => {
          cb();
          lazyContact.value = getEmptyContact();
          emit("updated", data);
        });

        createContactPronaht.mutate({
          input: { contact: pronahtContact },
        });
      }

      // save to PilVer
      if (addPilver.value === true) {
        const { ...pilverContact } = updateItem;
        if (props.type !== "Pilver") {
          pilverContact.companyId = pilverId.value;
        }

        createContactPilver.onDone((data) => {
          cb();
          lazyContact.value = getEmptyContact();
          emit("updated", data);
        });

        createContactPilver.mutate({
          input: { contactPilver: pilverContact },
        });
      }
    };

    const chbClickPronaht = () => {
      if (addPronaht.value === true) {
        loading.value = true;
        const { onResult } = useCompanyIdQuery({ name: props.company.Name });

        onResult((c) => {
          console.log(c.data.companyId.Id);
          pronahtId.value = c.data.companyId.Id;
          loading.value = false;
        });
      }
    };

    const chbClickPilver = () => {
      if (addPilver.value === true) {
        loading.value = true;
        const { onResult } = useCompanyPilverIdQuery({
          name: props.company.Name,
        });

        onResult((c) => {
          console.log(c.data.companyPilverId.Id);
          pilverId.value = c.data.companyPilverId.Id;
          loading.value = false;
        });
      }
    };

    return {
      update,
      dialog,
      addPronaht,
      addPilver,
      chbClickPronaht,
      chbClickPilver,
      lazyContact,
      loading,
      open: () => dialog.value.open(),
    };
  },
};
</script>

<style>
.v-messages.theme--light {
  display: none;
}
</style>
