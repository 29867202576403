<template>
  <edit-dialog-base @update="update" :loading="creatingContact" ref="dialog">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-pencil
        </v-icon>
      </slot>
    </template>
    <template #headline
      >Upraviť kontaktovanie firmy
      {{ contact ? contact.company.Name : company.Name }}</template
    >
    <template #form>
      <contact-form v-model="lazyContact"></contact-form>
    </template>
  </edit-dialog-base>
</template>

<script>
import ContactForm from "./Forms/ContactForm.vue";
import EditDialogBase from "./EditDialogBase.vue";
import {
  useCreateOneContactMutation,
  useCreateOneContactPilverMutation,
  useUpdateOneContactMutation,
} from "@/generated/backend";
import { computed, ref, watch } from "vue-demi";
import { getEmptyContact } from "@/services/utils";
export default {
  components: { EditDialogBase, ContactForm },
  props: {
    contact: {
      type: Object,
    },
    company: {
      type: Object,
    },
    type: { type: String },
  },

  setup(props, { emit }) {
    const lazyContact = ref(
      props.contact
        ? { ...props.contact, companyId: props.contact.company.Id }
        : { ...getEmptyContact(), companyId: props.company.Id }
    );
    watch(
      () => props.contact,
      (c) =>
        (lazyContact.value = c.company ? c : { ...c, company: props.company })
    );

    const {
      mutate: updateContact,
      loading: creatingContact,
      onDone: creatingFinished,
    } = props.type === "Pilver"
      ? useCreateOneContactPilverMutation({})
      : useCreateOneContactMutation({});

    const dialog = ref(null);

    const update = (cb) => {
      creatingFinished((data) => {
        cb();
        emit("updated", data);
      });
      const {
        __typename,
        Id,
        CreatedAt,
        DeletedAt,
        UpdatedAt,
        company,
        contactedBy,
        ...updateItem
      } = lazyContact.value;
      updateContact({
        input:
          props.type === "Pilver"
            ? { contactPilver: updateItem }
            : { contact: updateItem },
      });
    };

    return {
      update,
      dialog,
      lazyContact,
      creatingContact,
      open: () => dialog.value.open(),
      close: () => dialog.value.close(),
    };
  },
};
</script>

<style></style>
