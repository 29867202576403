<template>
  <div class="tw-max-w-7xl tw-mx-auto tw-m-8">
    <project-card
      @updated="onEdit"
      @removed="onDelete"
      :loading="loading"
      :project="project"
    ></project-card>

    <project-change-card
      class="mt-8"
      @updated="onProjectChangeEdit"
      :loading="loading || (projectChangeLoading && !!project.projectChange)"
      :projectChange="projectChange"
      :project="project"
      :showEditProjectChange="!!$route.params.on"
    ></project-change-card>

    <project-changes-table
      v-if="project"
      class="mt-8 tw-max-w-4xl tw-mx-auto"
      ref="table"
      :loading="loading"
      :project="project"
    ></project-changes-table>
  </div>
</template>

<script>
import ProjectCard from "@/components/Cards/ProjectCard.vue";
import ProjectChangesTable from "@/components/Tables/ProjectChangesTable.vue";
import { useProjectQuery, useProjectChangeQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import router from "@/router";
import { computed, nextTick, ref } from "vue-demi";
import ProjectChangeCard from "@/components/Cards/ProjectChangeCard.vue";

export default {
  components: { ProjectCard, ProjectChangesTable, ProjectChangeCard },
  setup(props, { emit }) {
    const table = ref(null);
    const { loading, result, refetch, onResult } = useProjectQuery({
      id: router.currentRoute.params.id,
    });

    const enabledProjectChangeQuery = computed(
      () => !!project.value?.projectChange
    );

    const project = useResult(result, null, (r) => r.project);

    const {
      loading: projectChangeLoading,
      result: projectchangeResult,
      refetch: projectchangeRefetch,
    } = useProjectChangeQuery(
      () => ({
        id: project.value?.projectChange?.Id,
      }),
      { enabled: enabledProjectChangeQuery }
    );

    const projectChange = useResult(
      projectchangeResult,
      null,
      (r) => r.projectChange
    );

    return {
      table,
      loading,
      project,
      projectChangeLoading,
      projectChange,
      onProjectChangeEdit: async () => {
        await refetch();
        await projectchangeRefetch();
        table.value.reloadTable();
      },
      onDelete: () => router.push({ name: "Projects" }),
      onEdit: () => refetch(),
    };
  },
};
</script>

<style></style>
