


























































import { nextTick, Ref, ref, watch } from "vue-demi";
import BaseTable from "./BaseTable.vue";
import {
  CompaniesQuery,
  ContactSortFields,
  SortDirection,
  useCompaniesQuery,
} from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import router from "@/router";
import { DateTime } from "luxon";
import Calendar from "../calendar.vue";
import InterestAutocomplete from "../Autocomplete/InterestCombobox.vue";

export default {
  components: {
    BaseTable,
    Calendar,
    InterestAutocomplete,
  },
  setup() {
    const table = ref(null);
    const olderThan = ref(DateTime.local().minus({ days: 30 }).toISODate());
    const interest = ref(undefined);

    watch([olderThan, interest], () => {
      nextTick(() => {
        (table as any).value.searchChanged();
      });
    });

    return {
      table,
      olderThan,
      interest,
      headers: [
        {
          text: "Posledný kontakt",
          value: "UpdatedAt",
          sortable: true,
          searchable: false,
        },
        {
          text: "Záujem",
          value: "interest",
          sortable: false,
        },
        {
          text: "Názov",
          align: "start",
          value: "Name",
          searchable: true,
        },
        { text: "Web", value: "Webpage", searchable: true },
        { text: "Zameranie", value: "Focus", searchable: true },
      ],
      query: (v) =>
        useCompaniesQuery({
          companiesNodesNodesContactsPaging: { limit: 1, offset: 0 },
          companiesNodesNodesContactsSorting: [
            {
              direction: SortDirection.Asc,
              field: ContactSortFields.UpdatedAt,
            },
          ],
          ...v,
        }),
      itemsGetter: (result: Ref<CompaniesQuery>) =>
        useResult(result, [], (d) => d.companies.nodes),
      totalCountGetter: (result: Ref<CompaniesQuery>) =>
        useResult(result, 0, (d) => d.companies.totalCount),
      reloadTable: () => (table as any).value.reload(),
      onRowClick: (item) =>
        router.push({ name: "CompanyDetail", params: { id: item.Id } }),
    };
  },
};
