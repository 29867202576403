<template>
  <div class="tw-max-w-7xl tw-mx-auto tw-m-8">
    <worker-card
      @updated="onEdit"
      @removed="onDelete"
      :loading="loading"
      :worker="worker"
    ></worker-card>
  </div>
</template>

<script>
import WorkerCard from "@/components/Cards/WorkerCard.vue";
import { useWorkerQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import router from "@/router";
import { computed, ref } from "vue-demi";

export default {
  components: { WorkerCard },
  setup(props, { emit }) {
    const table = ref(null);
    const { loading, result, refetch } = useWorkerQuery({
      id: router.currentRoute.params.id,
    });

    const worker = useResult(result, null, (r) => r.worker);

    return {
      table,
      loading,
      worker,
      onWorkerChangeEdit: async () => {
        await refetch();
        table.value.reloadTable();
      },
      onDelete: () => router.push({ name: "Workers" }),
      onEdit: () => refetch(),
    };
  },
};
</script>

<style></style>
