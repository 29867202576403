<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [20, 50, 100],
      }"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      @click:row="$emit('click:row', $event)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ tableTitle }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            filled
            rounded
            dense
            label="Hľadať"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <slot name="create-dialog"></slot>
        </v-toolbar>
      </template>
      <template
        v-for="column in headers"
        v-slot:[getSlotName(column)]="{ item }"
      >
        <slot :name="column.value" :item="item">{{ item[column.value] }} </slot>
      </template>

      <template v-slot:item.actions="{ item }">
        <slot name="edit-dialog" :item="item"></slot>
        <slot name="remove-dialog" :item="item">
          <v-icon small> mdi-delete </v-icon>
        </slot>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch, nextTick } from "vue-demi";
import { SortNulls } from "../../generated/backend";
export default {
  components: {},
  props: {
    headers: { type: Array },
    query: { type: Function },
    itemsGetter: { type: Function },
    totalCountGetter: { type: Function },
    value: { type: Object },
    tableTitle: { type: String },
    defaultFilter: { type: Object, default: undefined },
    sortBy: { type: String, default: "UpdatedAt" },
    sortDesc: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    emit("input", props.emptyObject);

    const options = ref({});
    const search = ref("");
    const { result, loading, refetch } = props.query({
      filter: props.defaultFilter,
    });
    const items = props.itemsGetter(result);
    const totalCount = props.totalCountGetter(result);
    const selectedItem = ref(null);

    const reload = async () => {
      loading.value = true;
      const params = {
        paging: {
          limit: options.value.itemsPerPage,
          offset: (options.value.page - 1) * options.value.itemsPerPage,
        },
        filter: props.defaultFilter,
      };

      if (search.value) {
        params["filter"] = {
          ...params["filter"],
          or: props.headers
            .filter((h) => h.searchable)
            .map((h) =>
              h.searchFunc
                ? h.searchFunc(search.value)
                : {
                    [h.value]: {
                      iLike: search.value ? `%${search.value}%` : undefined,
                    },
                  }
            ),
        };
      }

      if (options.value.sortBy[0]) {
        params["sorting"] = {
          field: options.value.sortBy[0] || undefined,
          direction: options.value.sortDesc?.[0] ? "ASC" : "DESC",
          nulls: options.value.sortDesc?.[0]
            ? SortNulls.NullsFirst
            : SortNulls.NullsLast,
        };
      }

      await refetch(params);
      loading.value = false;
    };

    const searchChanged = () => {
      options.value.page = 1;
      reload();
    };

    watch(search, searchChanged);
    watch(options, reload, { deep: true });

    return {
      options,
      loading,
      items,
      totalCount,
      search,
      reload,
      selectedItem,
      getSlotName: (c) => "item." + c.value,
      searchChanged,
    };
  },
};
</script>

<style></style>
