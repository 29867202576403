<template>
  <cars-table class="tw-max-w-7xl tw-mx-auto tw-m-8"></cars-table>
</template>

<script>
import CarsTable from "../components/Tables/CarsTable.vue";

export default {
  components: { CarsTable },
};
</script>

<style></style>
