<template>
  <create-dialog-base @create="create" :loading="creatingUser">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-btn color="primary" depressed dark class="" v-bind="attrs" v-on="on">
          Pridať nového užívateľa
        </v-btn>
      </slot>
    </template>
    <template #headline> Pridať nového užívateľa </template>
    <template #form>
      <user-form v-model="form"></user-form>
    </template>
  </create-dialog-base>
</template>

<script>
import { getEmptyUser } from "@/services/utils";
import { ref } from "vue-demi";
import UserForm from "./Forms/UserForm.vue";
import CreateDialogBase from "./CreateDialogBase.vue";
import { useCreateOneUserMutation } from "@/generated/backend";
export default {
  components: { CreateDialogBase, UserForm },
  setup(props, { emit }) {
    const form = ref(getEmptyUser());
    const {
      mutate: createUser,
      loading: creatingUser,
      onDone: creatingFinished,
    } = useCreateOneUserMutation({});

    const create = (cb) => {
      creatingFinished((data) => {
        cb();
        form.value = getEmptyUser();
        emit("created", data);
      });
      createUser({ input: { user: form.value } });
    };

    return {
      form,
      create,
      creatingUser,
    };
  },
};
</script>

<style></style>
