<template>
  <v-card class="tw-mx-auto tw-max-w-3xl" :loading="loading">
    <card-loader v-if="loading"></card-loader>
    <template v-else>
      <div class="tw-px-4 tw-pb-5 tw-pt-2 sm:tw-px-6 tw-flex">
        <router-link
          :to="{ name: 'ProjectDetail', params: { id: project.Id } }"
          class="
            tw-text-lg
            tw-leading-6
            tw-font-medium
            tw-text-gray-900
            tw-flex
            tw-items-center
          "
        >
          <v-icon color="primary" class="mr-2"
            >mdi-file-document-outline</v-icon
          >
          Projekt {{ project.Name }}
        </router-link>
        <v-spacer></v-spacer>
        <edit-project-dialog
          @updated="$emit('updated')"
          :project="project"
          v-slot:default="{ on, attrs }"
        >
          <v-btn fab depressed color="warning" small v-bind="attrs" v-on="on">
            <v-icon dense>mdi-pencil</v-icon>
          </v-btn>
        </edit-project-dialog>
        <delete-project-dialog
          @removed="$emit('removed')"
          :project="project"
          v-slot:default="{ on, attrs }"
        >
          <v-btn
            fab
            depressed
            color="error"
            small
            v-bind="attrs"
            v-on="on"
            class="ml-2"
          >
            <v-icon dense>mdi-delete</v-icon>
          </v-btn></delete-project-dialog
        >
      </div>
      <div class="tw-border-t tw-border-gray-200 tw-px-4 tw-py-5 sm:tw-p-0">
        <dl class="sm:tw-divide-y sm:tw-divide-gray-200">
          <card-row>
            <template #title>Názov</template>
            <template #value>{{ project.Name }}</template>
          </card-row>
          <card-row>
            <template #title>Adresa</template>
            <template #value>{{ project.Address }}</template>
          </card-row>
          <card-row>
            <template #title>Typ</template>
            <template #value>{{ project.Type }}</template>
          </card-row>
          <card-row>
            <template #title>Jazyk</template>
            <template #value>{{ project.Language }}</template>
          </card-row>
          <card-row>
            <template #title>Vedúci projektu</template>
            <template #value>{{ project.Manager }}</template>
          </card-row>
          <card-row>
            <template #title>Kontakt</template>
            <template #value>{{ project.ManagerContact }}</template>
          </card-row>
          <card-row>
            <template #title>Aktualizované</template>
            <template #value>{{ project.UpdatedAt | luxon }}</template>
          </card-row>
          <card-row>
            <template #title>Vytvorené</template>
            <template #value>{{ project.CreatedAt | luxon }}</template>
          </card-row>
          <card-row>
            <template #title>Vytvoril</template>
            <template #value
              ><router-link
                v-if="project.createdBy"
                :to="{
                  name: 'UserDetail',
                  params: { id: project.createdBy.Id },
                }"
                >{{ project.createdBy.Username }}</router-link
              >
              <span v-else>Neznámy</span>
            </template>
          </card-row>
        </dl>
      </div>
    </template>
  </v-card>
</template>

<script>
import { useProjectQuery } from "@/generated/backend";
import DeleteProjectDialog from "../Dialogs/DeleteProjectDialog.vue";
import EditProjectDialog from "../Dialogs/EditProjectDialog.vue";
import CardLoader from "./CardLoader.vue";
import CardRow from "./CardRow.vue";
import { useResult } from "@vue/apollo-composable";
import User from "@/services/auth";

export default {
  components: { CardRow, CardLoader, EditProjectDialog, DeleteProjectDialog },
  props: {
    loading: { type: Boolean },
    project: { type: Object },
  },
  setup() {
    return {
      User,
    };
  },
};
</script>

<style></style>
