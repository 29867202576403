


































import { Ref, ref } from "vue-demi";
import BaseTable from "./BaseTable.vue";
import { WorkersQuery, useWorkersQuery } from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import EditWorkerDialog from "../Dialogs/EditWorkerDialog.vue";
import DeleteWorkerDialog from "../Dialogs/DeleteWorkerDialog.vue";
import router from "@/router";

export default {
  components: {
    BaseTable,
    EditWorkerDialog,
    DeleteWorkerDialog,
  },
  props: {
    user: {
      type: Object,
    },
  },
  setup(props) {
    const table = ref(null);

    return {
      table,
      headers: [
        {
          text: "Aktualizované",
          value: "UpdatedAt",
          sortable: true,
          searchable: false,
          width: "20%",
        },
        {
          text: "Meno",
          align: "start",
          value: "Name",
          searchable: true,
        },
        { text: "Popis", value: "Note", searchable: true },
        { text: "Profesia", value: "Profession", searchable: true },
        { text: "Typ", value: "Type", searchable: true },
        { text: "", value: "actions", sortable: false },
      ],

      useWorkersQuery,
      itemsGetter: (result: Ref<WorkersQuery>) =>
        useResult(result, [], (d) => d.workers.nodes),
      totalCountGetter: (result: Ref<WorkersQuery>) =>
        useResult(result, 0, (d) => d.workers.totalCount),
      reloadTable: () => (table as any).value.reload(),
      onRowClick: (item) =>
        router.push({ name: "WorkerDetail", params: { id: item.Id } }),
    };
  },
};
