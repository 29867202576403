





























import { nextTick, Ref, ref, watch } from "vue-demi";
import BaseTable from "./BaseTable.vue";
import {
  DeletedCompaniesQuery,
  useDeletedCompaniesQuery,
} from "@/generated/backend";
import { useResult } from "@vue/apollo-composable";
import {
  DeletedCompanyPilversQuery,
  useDeletedCompanyPilversQuery,
} from "../../generated/backend";

export default {
  components: {
    BaseTable,
  },
  props: {
    type: { type: String },
  },
  setup(props) {
    const table = ref(null);
    const fromDate = ref(null);
    const focus = ref(null);

    watch([fromDate, focus], () => {
      nextTick(() => {
        (table as any).value.searchChanged();
      });
    });

    return {
      table,
      fromDate,
      focus,
      headers: [
        {
          text: "Odstránené",
          value: "DeletedAt",
          sortable: true,
          searchable: false,
        },
        {
          text: "Názov",
          align: "start",
          value: "Name",
          searchable: true,
        },
        { text: "Zameranie", value: "Focus", searchable: true },
        { text: "Web", value: "Webpage", searchable: true, width: "200px" },
        { text: "Odstránil", value: "deletedBy", searchable: true },
      ],
      query:
        props.type === "Pilver"
          ? useDeletedCompanyPilversQuery
          : useDeletedCompaniesQuery,
      itemsGetter:
        props.type === "Pilver"
          ? (result: Ref<DeletedCompanyPilversQuery>) =>
              useResult(result, [], (d) => d.deletedCompanyPilvers.nodes)
          : (result: Ref<DeletedCompaniesQuery>) =>
              useResult(result, [], (d) => d.deletedCompanies.nodes),

      totalCountGetter:
        props.type === "Pilver"
          ? (result: Ref<DeletedCompanyPilversQuery>) =>
              useResult(result, 0, (d) => d.deletedCompanyPilvers.totalCount)
          : (result: Ref<DeletedCompaniesQuery>) =>
              useResult(result, 0, (d) => d.deletedCompanies.totalCount),
      reloadTable: () => (table as any).value.reload(),
    };
  },
};
