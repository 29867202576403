<template>
  <delete-dialog-base @remove="remove" :loading="deletingWorker">
    <template #activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
          mdi-delete
        </v-icon>
      </slot>
    </template>
    <template #headline>Odstrániť subdodávateľa {{ lazyWorker.Name }}</template>
    <template #form>
      Naozaj chcete odstrániť subdodávateľa {{ lazyWorker.Name }}?
    </template>
  </delete-dialog-base>
</template>

<script>
import DeleteDialogBase from "./DeleteDialogBase.vue";
import { useDeleteOneWorkerMutation } from "@/generated/backend";
import { ref, watch } from "vue-demi";
export default {
  components: { DeleteDialogBase },
  props: {
    worker: {
      type: Object,
    },
  },

  setup(props, { emit }) {
    const lazyWorker = ref(props.worker);
    watch(
      () => props.worker,
      (c) => (lazyWorker.value = c)
    );

    const {
      mutate: removeWorker,
      loading: deletingWorker,
      onDone: deletingFinished,
    } = useDeleteOneWorkerMutation({});

    const remove = (cb) => {
      deletingFinished((data) => {
        cb();
        emit("removed", data);
      });
      removeWorker({
        input: { id: lazyWorker.value.Id },
      });
    };

    return {
      remove,
      lazyWorker,
      deletingWorker,
    };
  },
};
</script>

<style></style>
